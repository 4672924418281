import * as React from "react";
import "./Schnellreise.css";
import { statePlayerPositionS } from "../store";
import { stateWallPush } from "../store";
import * as THREE from "three";
import { useSnapshot } from "valtio";



const TransparentWindow = ({ listItems, setIsOpenList, isOpenList }) => {
  const snapPlayerPositionS = useSnapshot(statePlayerPositionS);
//   const snapStateWallPush = useSnapshot(stateWallPush)
  const [savedIndex, setSavedIndex] = React.useState(false);

  const [click, setClick] = React.useState(false);
  const [playerTravelPosition, setPlayerTravelPosition] = React.useState(new THREE.Vector3());
  const [playerTravelRotation, setPlayerTravelRotation] = React.useState(new THREE.Euler());

  React.useEffect(() => {
    setTimeout(() => {
      if (!snapPlayerPositionS.blockPlayerTravel && click && statePlayerPositionS.teleportScreen ) {
        //   statePlayerPositionS.playerPositionS = new THREE.Vector3(-425.281, 0.0, -224.439);
        //   statePlayerPositionS.playerRotationS = new THREE.Euler(0, 90, 0);
        statePlayerPositionS.playerPositionS = playerTravelPosition;
        statePlayerPositionS.playerRotationS = playerTravelRotation;
        statePlayerPositionS.travelclicked = true;
        setClick(false);
        statePlayerPositionS.teleportScreen = false
      }
    }, 1000); // Adjust the delay time as needed (in milliseconds)
  }, [snapPlayerPositionS.blockPlayerTravel]);



React.useEffect(()=>{

    switch (savedIndex) {
        case "Landkarte":
        case "Map":
          // statePlayerPositionS.homeclicked = true
          // stateWallPush.activeThemeIndex = -1; 
          if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 0; //Salzabbau
          setPlayerTravelPosition(
            new THREE.Vector3(288.237, 5, 0)
          );
          setPlayerTravelRotation(new THREE.Euler(0, 90, 0));
          setClick(true);
        }
          break;
        case "Arbeitswelt":
        case "Working world":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 5; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(35.5649, 3.99926, 403.642));
          setPlayerTravelRotation(new THREE.Euler(0, -120, 0));
          setClick(true);
        }
          break;
        case "Heimat":
        case "Folk culture":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 5; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(192.123, 3.99926, 704.436));
          setPlayerTravelRotation(new THREE.Euler(0, -120, 0));
          setClick(true);
        }
          break;
        case "Glaube":
        case "Faith":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 5; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(414.537, 3.99926, 723.653));
          setPlayerTravelRotation(new THREE.Euler(0, 120, 0));
          setClick(true);
        }
          break;
        case "Gewalt":
        case "Violence":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 5; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(412.951, 3.99926, 291.441));
          setPlayerTravelRotation(new THREE.Euler(0, 90, 0));
          setClick(true);
        }
          break;
          case "Macht":
            case "The power":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 5; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(535.545, 3.99926, 503.78));
          setPlayerTravelRotation(new THREE.Euler(0, 90, 0));
          setClick(true);
        }
          break;
        case "Zeitmaschine Bergmann":
          case "Time Machine cultural history":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 5; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(236.803, 3.99926, 407.539));
          setPlayerTravelRotation(new THREE.Euler(0, 120, 0));
          setClick(true);
        }
          break;
        case "Schifffahrt":
          case "Salt shipping":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 4; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-348.552, 8.67402, 239.582));
          setPlayerTravelRotation(new THREE.Euler(0, -90, 0));
          setClick(true);
        }
          break;
          case "Solerohre":
            case "Pipeline":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 4; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-530.931, 8.67402, 351.807));
          setPlayerTravelRotation(new THREE.Euler(0, -90, 0));
          setClick(true);
        }
          break;
          case "Die Städte":
            case "The cities":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 4; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-149.456, 8.67402, 536.346));
          setPlayerTravelRotation(new THREE.Euler(0, 120, 0));
          setClick(true);
        }
          break;
          case "Transport 1850":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 4; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-66.9883, 8.67402, 393.508));
          setPlayerTravelRotation(new THREE.Euler(0, 120, 0));
          setClick(true);
        }
          break;
          case "Die Traun":
            case "Traun River":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 4; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-166.187, 8.67402, 273.056));
          setPlayerTravelRotation(new THREE.Euler(0, -120, 0));
          setClick(true);
        }
          break;
          case "Zeitmaschine Transport":
            case "Time machine transport of salt":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 4; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-303.982, 8.67402, 481.582));
          setPlayerTravelRotation(new THREE.Euler(0, -120, 0));
          setClick(true);
        }
          break;
          case "Entstehung des Salzes":
            case "The origin of salt":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 3; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-468.981, 9.96984, -148.394));
          setPlayerTravelRotation(new THREE.Euler(0, 90, 0));
          setClick(true);
        }
          break;
          case "Prähistorie":
            case "Prehistory":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 3; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-760.522, 9.96984, -221.715));
          setPlayerTravelRotation(new THREE.Euler(0, 60, 0));
          setClick(true);
        }
          break;
          case "Mittelalter und Neuzeit":
            case "Middle Ages and modern period":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 3; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-982.257, 9.96984, -25.8266));
          setPlayerTravelRotation(new THREE.Euler(0, 55, 0));
          setClick(true);
        }
          break;
          case "Moderner Bergbau":
            case "Modern mining":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 3; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-869.404, 9.96984, 146.757));
          setPlayerTravelRotation(new THREE.Euler(0, 0, 0));
          setClick(true);
        }
          break;
          case "Tourismus":
            case "Tourism":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 3; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-518.119, 9.96984, 146.757));
          setPlayerTravelRotation(new THREE.Euler(0, 0, 0));
          setClick(true);
        }
          break;
          case "Zeitmaschine Werkzeuge":
            case "Time machine tools":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 3; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-591.755, 9.96984, -47.5076));
          setPlayerTravelRotation(new THREE.Euler(0, 55, 0));
          setClick(true);
        }
          break;
          case "Bergrutsch":
            case "The prehistoric landslides":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 2; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-112.673, 3.2993, -278.313));
          setPlayerTravelRotation(new THREE.Euler(0, 90, 0));
          setClick(true);
        }
          break;
          case "Bergsturz":
            case "debris flows":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 2; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-189.479, 3.2993, -538.283));
          setPlayerTravelRotation(new THREE.Euler(0, 90, 0));
          setClick(true);
        }
          break;
          case "Almen":
            case "High alpine pastures on the Dachstein":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 2; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-201.61, 3.2993, -226.965));
          setPlayerTravelRotation(new THREE.Euler(0, 55, 0));
          setClick(true);
        }
          break;
          case "Eiszeitmodelle":
            case "Ice Age models":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 2; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-353.424, 3.2993, -448.118));
          setPlayerTravelRotation(new THREE.Euler(0, 55, 0));
          setClick(true);
        }
          break;
          case "Dachstein":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 2; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(-261.399, 3.2993, -454.381));
          setPlayerTravelRotation(new THREE.Euler(0, 60, 0));
          setClick(true);
        }
          break;
          case "Holz im Bergbau":
            case "Wood in mining":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 1; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(214.8, 4.40601, -308.85));
          setPlayerTravelRotation(new THREE.Euler(0, 20, 0));
          setClick(true);
        }
          break;
          case "Holz im Transportwesen":
            case "Wood in transport":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 1; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(356.592, 4.40601, -570.31));
          setPlayerTravelRotation(new THREE.Euler(0, -60, 0));
          setClick(true);
        }
          break;
          case "Werkzeuge":
            case "Tools":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 1; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(249.957, 4.40601, -631.875));
          setPlayerTravelRotation(new THREE.Euler(0, -14.5, 0));
          setClick(true);
        }
          break;
          case "Holzarbeit":
            case "Woodworking":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 1; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(162.215, 4.40601, -479.902));
          setPlayerTravelRotation(new THREE.Euler(0, -14.5, 0));
          setClick(true);
        }
          break;
          case "Papier":
            case "Paper":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 1; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(156.763, 4.40601, -342.358));
          setPlayerTravelRotation(new THREE.Euler(0, -14.5, 0));
          setClick(true);
        }
          break;
          case "Zeitmaschine Holz":
            if(snapPlayerPositionS.homeFinished && statePlayerPositionS.teleportScreen){
          stateWallPush.activeThemeIndex = 1; //Salzabbau
          setPlayerTravelPosition(new THREE.Vector3(195.755, 4.40601, -339.285));
          setPlayerTravelRotation(new THREE.Euler(0, -60, 0));
          setClick(true);
        }
          break;

        default:
          break;
      }
// if(snapStateWallPush.activeThemeIndex != -1){
//       setPastRoom(snapStateWallPush.activeThemeIndex)
//     }
},[snapPlayerPositionS.homeFinished])




  const handleButtonClick = (index) => {
   
    statePlayerPositionS.teleportScreen = true
    setIsOpenList(false);

    setSavedIndex(index)

statePlayerPositionS.homeclicked = true

  };
  const handleClose = () => {
    setIsOpenList(false);
  };

  if (!isOpenList) {
    return null; // Don't render anything if window is closed
  }

  return (
    <div className="transparent-window">
      <div className="close-button" onClick={handleClose}>
        X
      </div>
      <div className="list">
        <div className="list-row">
          {listItems.slice(0, 3).map((item, index) => (
            <button
              key={index}
              className="list-item"
              onClick={() => handleButtonClick(item)}
            >
              {item}
            </button>
          ))}
        </div>
        <div className="list-row">
          {listItems.slice(3, 6).map((item, index) => (
            <button
              key={index}
              className="list-item"
              onClick={() => handleButtonClick(item)}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransparentWindow;
