


import "./DeviceToOld.css";




export default function DeviceToOld({showDeviceToOld}) {


 

  return showDeviceToOld ? (
    <div className="oldBack">
      <div className="titelOld">SORRY</div>

      <div className="textOld">
This is a web app that depicts a 3D museum based on a 3D web engine. Unfortunately, we require a minimum amount of graphics power to offer you a smooth experience. Your device is not powerful enough. Please visit us again on a newer device.</div>
      {/* <div className="textProgress">{progress} % loaded</div> */}
    </div>
  ) : null;


};


