
import "./CustomLoaderProgress.css";
import { useSnapshot } from "valtio";
import { statePlayerPositionS } from "../store";



export default function TeleportingScreen() {

const snapStatePlayerPositionS = useSnapshot(statePlayerPositionS)



  return snapStatePlayerPositionS.teleportScreen ? (
    <div className="RoomLoaderBack">
      <div className="titel">TELEPORT</div>
     
      <div className ="loader"></div>
      <div className="text">initialized</div>
      {/* <div className="textProgress">{progress} % loaded</div> */}
    </div>
  ) : null;


};


