
import { stateInAreaRooms } from "../store";
import { RigidBody,} from "@react-three/rapier"
import { useSnapshot } from "valtio";
import { useGLTF } from "@react-three/drei";
import { useEffect } from "react";
import { disposeGLTF } from "../Utils/disposeGLTF";


export default function EntryRoomCollider() {

  const {entryRoomCollision,} = GlbLoaderEntryRoomCollider()
  const snapInAreaRooms = useSnapshot(stateInAreaRooms);

  return (
 <>
{snapInAreaRooms.inAreaRoomEntryRoom ?  <RigidBody type= "fixed" colliders="cuboid"
includeInvisible
>
      <primitive object={entryRoomCollision.scene} scale={1}  visible={false}/>
      </RigidBody> : null }

      </>     

)}

export const GlbLoaderEntryRoomCollider = () => {

      const entryRoomCollision = useGLTF(
        "../models/EntryRoom/EntryRoomFloorCollision.glb",
        { useDraco: true }
      );
      useEffect(() => {
        // Cleanup function to dispose of textures
        return () => {
      
          disposeGLTF([
            entryRoomCollision.scene,
          
     
          ]);
        };
      }, []);
    
    
      return {
            entryRoomCollision
      };
    }