
import "./LoadingBar.css";
import { motion, } from "framer-motion";
import { useEffect, useState } from "react";
import { stateLoadingScreen } from "../store";
import { useSnapshot } from "valtio";
import { stateEnglisch } from "../store";
import { useProgress } from "@react-three/drei";


// import { stateIndex } from "../store";

const bodyVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
 
  };

const regionIconVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
  },
  exit: {
    scale: 4,
  },
};

const LogoVariants = {
  hidden: {
    x: -1000,
  },
  visible: {
    x: 0,
  },
  exit: {
    x: 0,
  },
};

const ArrowVariants = {
    hidden: {
      x: 1000,
    },
    visible: {
      x: 0,
    },
    exit: {
      x: 0,
    },
  };

export default function LoadingBar({ started, onStarted }) {
  return (
    <>
      <LoadingBarKon started={started} onStarted={onStarted} />
    </>
  );
}

function LoadingBarKon({ started, onStarted }) {
 
  const snapLoadingPercent = useSnapshot(stateLoadingScreen)
  const {progress} = useProgress();
  const [aniRun, setAniRun] = useState(true);
  const [isEnglisch, setIsEnglisch] = useState(false);
  // const [aniRunEndDone, setAniRunEndDone] = useState(false);

  useEffect(() => {
    
   
    stateLoadingScreen.loadingScreenAnimate = aniRun

  }, [aniRun]);

  // useEffect(() => {
  //   console.log(aniRunEndDone)
    
  //   stateLoadingScreen.loadingScreenAnimate = aniRun

  // }, [aniRunEndDone]);

  const austriaClick = (event) => {
    setIsEnglisch(false)
    stateEnglisch.isEnglisch= false
  }


  const englischClick = (event) => {
    setIsEnglisch(true)
    stateEnglisch.isEnglisch= true
  }




  return (
    <>

      <motion.div className="startBackground" 
       key={"startBackground"}
      variants={ bodyVariants}
          
          animate="visible"
          exit="hidden"
          transition={{ type: "linear", duration: 0, delay: 1.5 }}
      >
            <div
          className= "textPercent">
          {snapLoadingPercent.basePercent} % loaded
          </div>

          <div className="containerPositionFlaggen">
        <div className="textLenguage">Choose your language:</div>
        <div className="flag-container"  onClick={austriaClick}>
            <img className={`flag ${!isEnglisch ? "clicked" : ""}`} src="UI/FlaggeAustria.gif" alt="Austrian Flag" tabIndex="0"/>
            </div>
        <div className="flag-container" onClick={englischClick}>
            <img className={`flag ${isEnglisch ? "clicked" : ""}`} src="UI/FlaggeEngland.gif" alt="English Flag" tabIndex="0"/>
        
        </div>
    </div>


        <motion.div
         key={"LogoContainer"}
          className="LogoContainer"
          variants={LogoVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ type: "spring", duration: 0.5, delay: 0.5, damping: 7,
          stiffness: 20,
        
         restDelta: 0.001}}
          onAnimationComplete={() => setAniRun(false)} 
          // onExitComplete={() => setAniRunEndDone(true)} 
        >
          <div className="Logo">SALZZEIT</div>
          <div className="LogoSentence">Salzkammergut Connected History</div>
          {/* <div className="LogoSentence2">Connected History</div> */}
        </motion.div>

      
        <button
      className={`startButton ${snapLoadingPercent.basePercent < 100 ? 'loading' : ''}`}
      // disabled={snapLoadingPercent.basePercent < 100}
      onClick={onStarted}
    >
      {snapLoadingPercent.basePercent < 100 ? (
        <span className="loadingText">LOADING</span>
      ) : (
        isEnglisch ? "entry" : "eintreten"
      )}
    </button>
        <motion.div className="image-container"  key={"image-container"}>
          {/* <img src="UI/Dot_Arrow.svg" alt="Museum Icon"/> */}

     
          <div
            className="loading-bar"
            style={{
              width: `${progress}%`,
            }}
          />
        
        </motion.div>
        <motion.img
         key={"dotArrow"}
          className="dotArrow"
          src="UI/Dot_Arrow_white.svg"
          alt="Museum Icon"

          variants={ArrowVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ type: "spring", duration: 0.5, delay: 0.5, damping: 7,
          stiffness: 20,
        
         restDelta: 0.001}}
          
        />
        <motion.img
         key={"MuseumIcon"}
          className="MuseumIcon"
          src="UI/IconMsueum.svg"
          alt="Museum Icon"
          variants={regionIconVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ type: "spring", duration: 0.5, delay: 0.5, damping: 7,
          stiffness: 20,
        
         restDelta: 0.001}}
        />
      </motion.div>
    </>
  );
}
