/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useContext, createContext } from "react";
import { Merged } from "@react-three/drei";
import GlbLoader from "../../Utils/GlbLoader";
import { useEffect } from "react";
const context = createContext();

export function Instances({  children, ...props }) {
    const { themeWall: {nodes, materials}} = GlbLoader();
const ref = useRef()

    useEffect(()=>{
        ref.current.traverse((node) => {
              
                  node.frustumCulled = false

              
        });
        
    
    
      },[])
    


  const instances = useMemo(
    () => ({
      EntryRoomWall: nodes.EntryRoom_Wall,
      // DoorRahmen: nodes.DoorRahmen,
      Saeule: nodes.Saeule,
      MonitorRahmen: nodes.MonitorRahmen,
      Top: nodes.Top,
      Leiste: nodes.Leiste,
    }),
    [nodes]
  );
  return (
    <Merged ref={ref} meshes={instances} {...props} >
      {(instances) => (
        <context.Provider value={instances} children={children} />
      )}
    </Merged>
  );
}

export function Model(props) {
  const instances = useContext(context);
  return (
    <group {...props} >
      <group scale={10}>
        <instances.EntryRoomWall position={[19.501, 0, 0]} scale={0.981} />
        {/* <instances.DoorRahmen position={[-0.896, 1.918, 0]} /> */}
        <instances.Saeule position={[2.829, 0.007, 11.291]} />
        <instances.MonitorRahmen position={[-0.123, 0.816, -6.375]} />
        <instances.Top position={[19.501, 0, 0]} scale={0.981} />
        <instances.Leiste position={[-0.03, -0.068, -11.249]} />
      </group>
    </group>
  );
}


