import "./ThemeMonitor.css";

import { useState, useRef, } from "react";
import { Text3D } from "@react-three/drei";

import GlbLoader from "../Utils/GlbLoader";
import { useSnapshot } from "valtio";
import IntroMonitor from "./IntroMonitor";
import { useFrame } from "@react-three/fiber";
import { stateInAreaRooms } from "../store";




export default function Infostation() {

 
  const [playerInsideArea, setPlayerInsideArea] = useState(false);
  const { infoStationModel } = GlbLoader();
  const snapInAreaRooms = useSnapshot(stateInAreaRooms);
  const infoStationModelRef = useRef();

  

  return (
    <>
{ snapInAreaRooms.inAreaRoomEntryRoom ?
<>
   <IntroMonitor></IntroMonitor>
      <InfoStationKon
       
      
        playerInsideArea={playerInsideArea}
      />

      <primitive
        castShadow
        receiveShadow
        ref={infoStationModelRef}
        object={infoStationModel.scene}
      />
     
    </> : null}
    </>
  );
}

function InfoStationKon() {
  const { salzKristall} = GlbLoader();


  const salzKristallRef = useRef();


  useFrame((state) => {

    salzKristallRef.current.rotation.y += 0.005; 

  });



  const themeFont = {
    font: "/Fonts/Roboto_Bold_size.json",

    letterSpacing: -0.05,
    lineHeight: 0.6,
    "material-toneMapped": false,
    fontSize: 3.5,
    // anchorX: "middle",
    // anchorY: "top",
  };

  return (
    <>
      <primitive
          ref={salzKristallRef}
          object={salzKristall.scene}
          // position={[0, 10, 0]}
          // scale={0.4}
          position={[0, 0, 0]}
          scale={1}
        >
       <Text3D
            {...themeFont}
           
            scale={8}
            position={[-5,50,1.7]}
            curveSegments={6
          }
          >
            {"Information"}
            <meshStandardMaterial color={"white"} roughness={0.1} />
          </Text3D>
          </primitive>
    </>
  );
}




