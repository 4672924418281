import * as THREE from 'three'

export default [
{
	Id: 'Wall_Salzabbau',
	Titel: 'Wall_Salzabbau',
	Position: new THREE.Vector3 (-195.011, 0.0, 6.10352e-05),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'Wall_Handelswege',
	Titel: 'Wall_Handelswege',
	Position: new THREE.Vector3 (-97.5056, 0.0, 168.885),
	Rotation: new THREE.Vector3 (0.0,0.0,-60.0)
},
{
	Id: 'Wall_Menschen',
	Titel: 'Wall_Menschen',
	Position: new THREE.Vector3 (97.5057, 0.0, 168.885),
	Rotation: new THREE.Vector3 (0.0,0.0,-120.0)
},
{
	Id: 'Wall_MuseenDerRegion',
	Titel: 'Wall_MuseenDerRegion',
	Position: new THREE.Vector3 (195.011, 0.0, -7.80836e-05),
	Rotation: new THREE.Vector3 (0.0,0.0,180.0)
},
{
	Id: 'Wall_Holzarbeit',
	Titel: 'Wall_Holzarbeit',
	Position: new THREE.Vector3 (97.5055, 0.0, -168.885),
	Rotation: new THREE.Vector3 (0.0,0.0,120.0)
},
{
	Id: 'Wall_Klima',
	Titel: 'Wall_Klima',
	Position: new THREE.Vector3 (-97.5057, 0.0, -168.885),
	Rotation: new THREE.Vector3 (0.0,0.0,60.0)
},
]