

import useSound from "use-sound";



const Sound = () => {

  const [playClickSound] = useSound('../Sounds/clickAudio_.mp3',{ volume: 0.3 })
  const [playMouseIn] = useSound('../Sounds/mouseOut.mp3', { volume: 0.3 });
  const [playMouseOut] = useSound('../Sounds/mouseIn.mp3', { volume: 0.3 });
  const [playTimelslider] = useSound('../Sounds/timeslider.mp3', { volume: 0.3 });
  const [playDoorOpen] = useSound('../Sounds/Door_open.mp3', { volume: 0.3 });
  const [playAbdeckungOpen] = useSound('../Sounds/Abdeckung_open.mp3', { volume: 0.3 });
  const [playFly] = useSound('../Sounds/fly.mp3', { volume: 0.1 });
  const [playCheckArea] = useSound('../Sounds/CheckInArea.mp3', { volume: 0.1 });




return {
  playClickSound,
  playMouseIn,
  playMouseOut,
  playTimelslider,
  playDoorOpen,
  playAbdeckungOpen,
  playFly,
  playCheckArea,
 
}
}

export default Sound;