import * as THREE from 'three'

export default [

	{
		Id: 'MuseenDerRegion',
		Titel: 'MuseenDerRegion',
		Position: new THREE.Vector3 (195.011, -1.01, -7.68661e-05),
		Rotation: new THREE.Vector3 (0.0,0.0,0)
	},
	{
		Id: 'Holzarbeit',
		Titel: 'Holzarbeit',
		Position: new THREE.Vector3 (97.5056, -1.01, -168.885),
		Rotation: new THREE.Vector3 (0.0,0.0,-59.9858)
	},
	{
		Id: 'Klima',
		Titel: 'Klima',
		Position: new THREE.Vector3 (-97.5057, -1.01, -168.885),
		Rotation: new THREE.Vector3 (0.0,0.0,-119.284)
	},
	{
		Id: 'Salzabbau',
		Titel: 'Salzabbau',
		Position: new THREE.Vector3 (-195.011, -1.01, 5.98907e-05),
		Rotation: new THREE.Vector3 (0.0,0.0,-179.741)
	},
	{
		Id: 'Handelswege',
		Titel: 'Handelswege',
		Position: new THREE.Vector3 (-97.5057, -1.01, 168.885),
		Rotation: new THREE.Vector3 (0.0,0.0,118.934)
	},
	{
		Id: 'Menschen',
		Titel: 'Menschen',
		Position: new THREE.Vector3 (97.5057, -1.01, 168.885),
		Rotation: new THREE.Vector3 (0.0,0.0,61.3093)
	},
]