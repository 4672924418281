// glbLoader.js
import { useGLTF, useProgress, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";

import { useSnapshot } from "valtio";
import { stateMemory } from "../store";
import { stateLoadingScreen } from "../store";
import { assignMaterialToObjects } from "./AssignMaterialToObject";
import { disposeGLTF } from "./disposeGLTF";
import { stateInAreaRooms } from "../store";



const buttonOriginalMaterial = new THREE.MeshBasicMaterial({
 
  color: "white",
  // transparent: true,
  // opacity: 0.35,

 
});


const GlbLoader = () => {



 const snapStateMemory = useSnapshot(stateMemory)
 const snapInAreaRooms = useSnapshot(stateInAreaRooms)


//  const landscape = useLoader(GLTFLoader,"../models/Museum01/MDR/Landscape.glb");
  // const themeMonitor = useLoader(GLTFLoader,
  //   "../models/EntryRoom/ThemeWalls/ThemeMonitor.glb",incrementLoadCount
  // );

  



  const { progress} = useProgress()



  const [
    themeHandelsWegeMap,
    themeMdrMap,
    themeSalzabbauMap,
    themeKlimaMap,
    themeMenschenMap,
    themeHolzarbeitMap,


    entryRoomInfoAOMap,
    entryRoomWallAOMap,
    entryRoomDoorAOMap,
    imperfectionRoughMapM,
    saltColorMap,
    saltNormalMap,
    saltRoughMap,
    glowAlphaMap,
    saltKristallColorMap,
    saltKristallNormalMap,
    wallPutzColorMap,
    wallPutzNormalMap,
    wallPutzRoughMap,
   

    waterColorMapM,
   
    themeInfoMap,
    waterNormalsM,
    ParkettColor,
    ParkettNormal,
    ParkettRough,

    videostationAO,
    play
  
  ] = [
    "Handelswege.jpg",
    "MDR.jpg",
    "Salzabbau.jpg",
    "Klima.jpg",
    "Menschen.jpg",
    "Holzarbeit.jpg",

    "entryRoomInfoAO.jpg",
    "entryRoomWallAO.jpg",
    "entryRoomDoorAOMap.jpg",
    "T_Imperfections_Wipe_Mask_rough.jpg",
    "salt_ColorMap.jpg",
    "salt_NormalMap.jpg",
    "salt_RoughMap.jpg",
    "Gradient_Glow.jpg",
    "SalzStein_ColorMap.jpg",
    "SalzStein_NormalMap.jpg",
    "WallPutz_ColorMap.jpg",
    "WallPutz_NormalMap.jpg",
    "WallPutz_RoughMap.jpg",
    "Water_ColorMap.jpg",
    "Info.jpg",
    "waternormals.jpg",
    "ParkettColor.jpg",
    "ParkettNormal.jpg",
    "ParkettRough.jpg",
   "VideoStationAO.webp",
   "Play.jpg"

  
  
  
  
  ].map((filename) => !snapStateMemory.memoryLowDevice ? useTexture(`../overallTextures/EntryRoom/${filename}`) : useTexture(`../overallTextures/EntryRoom/LOW/${filename}`));




  const waterColorMap = useMemo(() => waterColorMapM,[]);
  const imperfectionRoughMap = useMemo(() => imperfectionRoughMapM,[]);
  const waterNormals = useMemo(() => waterNormalsM,[]);

  // Now you can use memoizedArray in your component




  

const [
  // entryRoomFloorCollider,
  // entryRoomWallCollider,

  themeMonitor,
  themeWall,

  pointerModel,
  salzKristall,
  infoStationModel,
  doorButton, 
  introMonitor,
  timesliderPodestM,
  roundPodestM,
  artHolderRoundM,
  secretButtonM,
  VideoStationSmallM,
  playerModel,
  playerModelWoman,


 

  
] = useGLTF([
  // "../models/EntryRoom/EntryRoomFloorCollision.glb",
  // "../models/EntryRoom/ThemeWalls/ThemeWall_collision.glb",
  "../models/EntryRoom/ThemeWalls/ThemeMonitor.glb",
  "../models/EntryRoom/ThemeWalls/ThemeWall.glb",

  "../models/EntryRoom/Pointer.glb",
  "../models/EntryRoom/Salzstein.glb",
  "../models/EntryRoom/Infopoint.glb",
  "../models/EntryRoom/DoorButton.glb",
  "../models/EntryRoom/IntroMonitor.glb",
  "../models/Museum01/Menschen/TimeLine_Podest.glb",
  "../models/Museum01/RoundPodest.glb",
  "../models/Overall/ArtHolderRound.glb",
  "../models/Museum01/MDR/SecretButton.glb",
  "../models/EntryRoom/VideoStationSmall.glb",
  "../models/Player.glb",
  "../models/PlayerWoman.glb",

 
])


    useEffect(()=>{

      stateLoadingScreen.basePercent = progress
    },[progress])
    



 
 
 playerModelWoman.scene.name = "playerModelWoman"; // Set a unique name


  playerModel.scene.name = "playerModel"; // Set a unique name

  const timesliderPodest = useMemo(() => timesliderPodestM,[]);
  const roundPodest = useMemo(() => roundPodestM,[]);
  const artHolderRound = useMemo(() => artHolderRoundM,[]);
  const secretButton = useMemo(() => secretButtonM,[]);
  const VideoStationSmall = useMemo(() => VideoStationSmallM,[]);
  // const playerModel = useMemo(() => playerModelM,[playerModelM]);
  // const playerModelWoman = useMemo(() => playerModelWomanM,[playerModelM]);

//TexturesUI

  themeHandelsWegeMap.colorSpace = THREE.SRGBColorSpace;
  themeMdrMap.colorSpace = THREE.SRGBColorSpace;
  themeSalzabbauMap.colorSpace = THREE.SRGBColorSpace;
  themeKlimaMap.colorSpace = THREE.SRGBColorSpace;
  themeMenschenMap.colorSpace = THREE.SRGBColorSpace;
  themeHolzarbeitMap.colorSpace = THREE.SRGBColorSpace;
themeInfoMap.colorSpace = THREE.SRGBColorSpace;
 
  // InfoTextMap.flipY = false;
  //TEXTURE LOADING




  entryRoomInfoAOMap.channel = 1;

  entryRoomInfoAOMap.flipY = false;
  entryRoomInfoAOMap.colorSpace = THREE.SRGBColorSpace;

  entryRoomWallAOMap.flipY = false;
  entryRoomWallAOMap.colorSpace = THREE.SRGBColorSpace;
  entryRoomWallAOMap.channel = 1;

  entryRoomDoorAOMap.flipY = false;
  entryRoomDoorAOMap.colorSpace = THREE.SRGBColorSpace;
  entryRoomDoorAOMap.channel = 1;

  videostationAO.flipY = false;
  videostationAO.colorSpace = THREE.SRGBColorSpace;
  videostationAO.channel = 1;

  //Textures Overall

  play.flipY = false;
  play.colorSpace = THREE.SRGBColorSpace;


  // imperfectionRoughMap.encoding = THREE.sRGBEncoding;
  imperfectionRoughMap.flipY = false;
  imperfectionRoughMap.repeat.set(4, 4);
  imperfectionRoughMap.wrapS = THREE.RepeatWrapping;
  imperfectionRoughMap.wrapT = THREE.RepeatWrapping;


  //Salz texture

  glowAlphaMap.colorSpace = THREE.SRGBColorSpace;

  glowAlphaMap.wrapS = THREE.RepeatWrapping;
  glowAlphaMap.wrapT = THREE.RepeatWrapping;

  saltColorMap.flipY = false;
  // saltColorMap.repeat.set(4,4)
  saltColorMap.wrapS = THREE.RepeatWrapping;
  saltColorMap.wrapT = THREE.RepeatWrapping;

  saltNormalMap.flipY = false;
  saltNormalMap.wrapS = THREE.RepeatWrapping;
  saltNormalMap.wrapT = THREE.RepeatWrapping;

  saltRoughMap.flipY = false;
  saltRoughMap.wrapS = THREE.RepeatWrapping;
  saltRoughMap.wrapT = THREE.RepeatWrapping;


  saltKristallColorMap.colorSpace = THREE.SRGBColorSpace;

  saltKristallColorMap.flipY = false;
  // saltKristallColorMap.repeat.set(3,3)
  saltKristallColorMap.wrapS = THREE.RepeatWrapping;
  saltKristallColorMap.wrapT = THREE.RepeatWrapping;

  saltKristallNormalMap.flipY = false;
  // saltKristallNormalMap.wrapS = THREE.RepeatWrapping;
  // saltKristallNormalMap.wrapT = THREE.RepeatWrapping;

  // const wallPutzAOMap = useTexture("../overallTextures/WallPutz/WallPutz_AOMap.jpg");

  ParkettColor.flipY = false;
  ParkettNormal.flipY = false;
  ParkettRough.flipY = false;

  ParkettColor.colorSpace = THREE.SRGBColorSpace;


  ParkettColor.wrapS = THREE.RepeatWrapping;
  ParkettColor.wrapT = THREE.RepeatWrapping;
  ParkettNormal.wrapS = THREE.RepeatWrapping;
  ParkettNormal.wrapT = THREE.RepeatWrapping;
  ParkettRough.wrapS = THREE.RepeatWrapping;
  ParkettRough.wrapT = THREE.RepeatWrapping;

  wallPutzColorMap.flipY = false;
  wallPutzNormalMap.flipY = false;
  wallPutzRoughMap.flipY = false;
  // wallPutzAOMap.flipY = false;
  wallPutzColorMap.colorSpace = THREE.SRGBColorSpace;

  // wallPutzColorMap.repeat.set(6,6)
  wallPutzColorMap.wrapS = THREE.RepeatWrapping;
  wallPutzColorMap.wrapT = THREE.RepeatWrapping;

  // wallPutzNormalMap.repeat.set(6,6)
  wallPutzNormalMap.wrapS = THREE.RepeatWrapping;
  wallPutzNormalMap.wrapT = THREE.RepeatWrapping;

  // wallPutzRoughMap.repeat.set(6,6)
  wallPutzRoughMap.wrapS = THREE.RepeatWrapping;
  wallPutzRoughMap.wrapT = THREE.RepeatWrapping;

  // wallPutzAOMap.repeat.set(6,6)
  // wallPutzAOMap.wrapS = THREE.RepeatWrapping;
  // wallPutzAOMap.wrapT = THREE.RepeatWrapping;
  // wallPutzAOMap.encoding = THREE.sRGBEncoding;







  waterColorMap.flipY = false;
  

  waterColorMap.colorSpace = THREE.SRGBColorSpace;


  //MATERIAL ASSIGNMENT AND CREATIONS

  //CREATIONS





  const buttonHoverMaterial = new THREE.MeshBasicMaterial({
   
    color: "#fc8207",
    transparent: true,
    opacity: 1,

   
  });




  const blackMattMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.8,
    color: "#181818",

    roughnessMap: imperfectionRoughMap,
    // aoMap: staircaseAOMap
  });

  const greyMattMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.8,
    color: "#393939",

    roughnessMap: imperfectionRoughMap,
  aoMap: videostationAO
  });



  const blackMattInfoMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.8,
    color: "#181818",
    metalness: 0,
    // map:   entryRoomFloorAOMap,
    roughnessMap: imperfectionRoughMap,
    aoMap: entryRoomInfoAOMap,
    // aoMapIntensity: 1,

  });

  const blackMattVideoStationMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.8,
    color: "#262626",
    metalness: 0,
    // map:   entryRoomFloorAOMap,
    roughnessMap: imperfectionRoughMap,
    aoMap: videostationAO,
    // aoMapIntensity: 1,

  });

  const blackGlossyInfoMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.05,
    color: "#181818",
    metalness: 0,
    // map:   entryRoomFloorAOMap,
    roughnessMap: imperfectionRoughMap,
    aoMap: entryRoomInfoAOMap,
    // aoMapIntensity: 1,

  });

  const saltKristallMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.35,
    color: "orange",
    metalness: 0.5,
   map:   saltKristallColorMap,
    // roughnessMap: imperfectionRoughMap,
    // aoMap: entryRoomInfoAOMap,
    normalMap: saltKristallNormalMap,
    emissiveMap:saltKristallColorMap,
    transparent: true,
    opacity: 0.95,
    emissive: "white",
    // aoMapIntensity: 1,
emissiveIntensity: 2,
// blending: THREE.AdditiveBlending
  });

  const saltKristallHighlightMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.35,
    color: "orange",
    metalness: 0.5,
   map:   saltKristallColorMap,
    // roughnessMap: imperfectionRoughMap,
    // aoMap: entryRoomInfoAOMap,
    normalMap: saltKristallNormalMap,
    emissiveMap:saltKristallColorMap,
    transparent: true,
    opacity: 0.95,
    emissive: "orange",
    // aoMapIntensity: 1,
emissiveIntensity: 2,
// blending: THREE.AdditiveBlending
  });

  const fullBlack = new THREE.MeshBasicMaterial({
    
    color: "black",
    opacity: 0.85,
    transparent: true
    


  });




  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping


  const saltMaterial = new THREE.MeshStandardMaterial({
    // roughness: 1,
    metalness: 0,
    color: "#e1e1e1",
    map: saltColorMap,
    normalMap: saltNormalMap,
    roughnessMap: saltRoughMap,
    aoMap: entryRoomWallAOMap,
    aoMapIntensity: 0.85,
      // emissive: "white",
     emissiveMap: saltColorMap,
    emissiveIntensity: 2,
  });

  const saltDoorMaterial = new THREE.MeshStandardMaterial({
    // roughness: 1,
    metalness: 0,
    color: "#e1e1e1",
    map: saltColorMap,
    normalMap: saltNormalMap,
    roughnessMap: saltRoughMap,
    aoMap: entryRoomDoorAOMap,
    aoMapIntensity: 0.85,
      // emissive: "white",
     emissiveMap: saltColorMap,
    emissiveIntensity: 2,
  });




  const wallPutzMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.3,

    map: wallPutzColorMap,
    normalMap: wallPutzNormalMap,
    roughnessMap: wallPutzRoughMap,

    aoMap: entryRoomWallAOMap,
    //  lightmap: entryRoomWallAOMap,
    //  lightMapIntensity: 50
  });


  // const concreteFloorBMaterial = new THREE.MeshStandardMaterial({
  //   roughness: 1,
  //   // metalness: 1,
  //   map: concreteWallBColorMap,
  //   normalMap: concreteWallBNormalMap,
  //   roughnessMap: concreteWallBRoughMap,
  //   // aoMap: concreteWallBAOMap,

  //   aoMap: entryRoomFloorAOMap,
  // });




  const blackGlasMaterial = new THREE.MeshStandardMaterial({
    color: 0x000000,
    // metalness: 1,
    roughness: 0.1,
    transparent: true,
    opacity: 0.85,
  });

  // const blueGlas = new THREE.MeshStandardMaterial({
  //   map: waterColorMap,
  //   normalMap: waterTileNormalMap,
  //   // metalness: 1,
  //   roughness: 0.01,
  //   transparent: true,
  //   opacity: 0.75,
  //   // blending: THREE.AdditiveBlending
  // });

  const blueGlasClear = new THREE.MeshStandardMaterial({
    // map: waterColorMap,
    map: waterColorMap,
    // normalMap: waterTileNormalMap,
    // // metalness: 1,
    // roughness: 0.01,
    transparent: true,
    opacity: 0.6,
  });



  const whiteEmissiveMaterial = new THREE.MeshStandardMaterial({
    roughness: 0,
    metalness: 0,
    // opacity: 0.2,

    // side: DoubleSide,
    color: "white",
    //  emissive: "white",
    //  emissiveMap: cubeIllum,
    // emissiveIntensity: 1,
    toneMapped: false,
  });

  const clearGlasMaterial = new THREE.MeshStandardMaterial({
    roughness: 0,
    metalness: 0,
    opacity: 0.2,
    transparent: true,
    // side: DoubleSide,
    color: "white",
  });




  const orangeGlossyWall = new THREE.MeshStandardMaterial({
    roughness: 0,
    metalness: 0,
    opacity: 1,
    transparent: true,
    // side: DoubleSide,
    color: "#fc8207",
    aoMap: entryRoomWallAOMap,
  });


  const orangeGlossyVideoStationMaterial = new THREE.MeshStandardMaterial({
    roughness: 0,
    metalness: 0,
    opacity: 1,
    transparent: true,
    // side: DoubleSide,
    color: "#fc8207",
    aoMap: videostationAO,
  });

  const orangeGlossyInfo = new THREE.MeshStandardMaterial({
    roughness: 0,
    metalness: 0,
    opacity: 1,
    transparent: true,
    // side: DoubleSide,
    color: "#fc8207",
    aoMap: entryRoomInfoAOMap,
    aoMapIntensity: 0.8
  });


  const blackMattWallMaterialGlossy = new THREE.MeshStandardMaterial({
    roughness: 0.05,
    color: "#181818",
    metalness: 0,
    // map:   entryRoomFloorAOMap,
    roughnessMap: imperfectionRoughMap,
    aoMap: entryRoomWallAOMap,
    // aoMapIntensity: 1,
  });


  const kristallGlowMaterial = new THREE.MeshBasicMaterial({
  
    // color: "orange",
    map:glowAlphaMap,
    opacity: 0.65,
   blending: THREE.AdditiveBlending
    
 
  });
  //ASSIGNMENT

  useEffect(() => {

    assignMaterialToObjects(themeWall.scene,["Saeule"],saltMaterial);
    assignMaterialToObjects(themeWall.scene,["MonitorRahmen" ],orangeGlossyWall );
    assignMaterialToObjects(themeMonitor.scene,["Box215"],blackMattMaterial);
    assignMaterialToObjects(themeWall.scene,["MonitorRahmen"],blackMattWallMaterialGlossy );
    assignMaterialToObjects(themeWall.scene,["EntryRoom_Wall","Top"],wallPutzMaterial);
    assignMaterialToObjects(themeWall.scene, ["Leiste"], orangeGlossyWall );

   
    assignMaterialToObjects(salzKristall.scene, ["Salzstein"], saltKristallMaterial );
    assignMaterialToObjects(salzKristall.scene, ["Glow"], kristallGlowMaterial );
  


    assignMaterialToObjects(pointerModel.scene,["DoorRight", "DoorLeft"],saltDoorMaterial);
    assignMaterialToObjects(pointerModel.scene,["GummiRand", "GummiS02", "GummiS01"],blackMattWallMaterialGlossy);
    assignMaterialToObjects(pointerModel.scene,["DoorRahmen"],orangeGlossyWall);

    assignMaterialToObjects(playerModel.scene, ["man_tshirt_shortsWithPockets","head_luke"], fullBlack);
    assignMaterialToObjects(playerModelWoman.scene, ["woman_tshirt_shorts_schoes","head_jody"], fullBlack);

    assignMaterialToObjects(infoStationModel.scene, ["Black","table01"], blackMattInfoMaterial);
    assignMaterialToObjects(infoStationModel.scene, ["White","steher01"], orangeGlossyInfo);
    assignMaterialToObjects(infoStationModel.scene, ["rand01","Stairt010"], blackGlossyInfoMaterial );
    assignMaterialToObjects(introMonitor.scene, ["InfoMonitor"], blackMattInfoMaterial);
    

    assignMaterialToObjects(doorButton.scene, ["Halterung001"], blackGlasMaterial);
    assignMaterialToObjects(doorButton.scene, ["Button001"], buttonOriginalMaterial);
    assignMaterialToObjects(doorButton.scene, ["PUSH"], buttonHoverMaterial);
    assignMaterialToObjects(timesliderPodest.scene,["Halterung003"],blackGlasMaterial);

    assignMaterialToObjects(artHolderRound.scene,["White"],whiteEmissiveMaterial);
    assignMaterialToObjects(artHolderRound.scene,["Black"],blackMattMaterial);

    assignMaterialToObjects(secretButton.scene,["Halterung"],blackGlasMaterial);
    assignMaterialToObjects(VideoStationSmall.scene,["VideoStaenderBack"],blackMattVideoStationMaterial);
    assignMaterialToObjects(VideoStationSmall.scene,["VideoStaenderFront"],greyMattMaterial);
    assignMaterialToObjects(VideoStationSmall.scene,["VideostaenderColor"],orangeGlossyVideoStationMaterial);
    
  }, []);



  useEffect(() => {
    // Cleanup function to dispose of textures
    return () => {
      // Loop through all textures and dispose of them
      [
        themeHandelsWegeMap,
        themeMdrMap,
        themeSalzabbauMap,
        themeKlimaMap,
        themeMenschenMap,
        themeHolzarbeitMap,
  
        entryRoomInfoAOMap,
        entryRoomWallAOMap,
        entryRoomDoorAOMap,
        saltColorMap,
        saltNormalMap,
        saltRoughMap,
        glowAlphaMap,
        saltKristallColorMap,
        saltKristallNormalMap,
        themeInfoMap,
        ParkettColor,
        ParkettNormal,
        ParkettRough,
       
      ].forEach(texture => {
        // Check if texture is not null or undefined and has dispose method
        if (texture && texture.dispose) {
          texture.dispose();
        }
      });
    };
  }, [snapInAreaRooms.inAreaRoomEntryRoom]);


  // Usage in useEffect
useEffect(() => {

  if(!snapInAreaRooms.inAreaRoomEntryRoom){
   disposeGLTF([
     // themeMonitor.scene,
     // themeWall.scene,
     // entryRoomModel.scene,
     // pointerModel.scene,
     // salzKristall.scene,
     // infoStationModel.scene,
     // doorButton.scene,
     // introMonitor.scene
     themeMonitor.scene,
     themeWall.scene,
   
     pointerModel.scene,
     salzKristall.scene,
     infoStationModel.scene,
     doorButton.scene, 
     introMonitor.scene,
     timesliderPodestM.scene,
     roundPodestM.scene,
     artHolderRoundM.scene,
     secretButtonM.scene,
     VideoStationSmallM.scene,
     playerModel.scene,
     playerModelWoman.scene,

   ]);
 }
   // Cleanup function to dispose of GLTF scenes
   return () => {
    
     disposeGLTF([
       // themeMonitor.scene,
       // themeWall.scene,
       // entryRoomModel.scene,
       // pointerModel.scene,
       // salzKristall.scene,
       // infoStationModel.scene,
       // doorButton.scene,
       // introMonitor.scene
       themeMonitor.scene,
       themeWall.scene,
    
       pointerModel.scene,
       salzKristall.scene,
       infoStationModel.scene,
       doorButton.scene, 
       introMonitor.scene,
       timesliderPodestM.scene,
       roundPodestM.scene,
       artHolderRoundM.scene,
       secretButtonM.scene,
       VideoStationSmallM.scene,
       playerModel.scene,
       playerModelWoman.scene,
      
  
     ]);
   };
 }, [snapInAreaRooms.inAreaRoomEntryRoom]);
  return {
  
    pointerModel,
    playerModel,
    playerModelWoman,
    themeMonitor,
    themeWall,
    // entryRoomFloorCollider,
    // entryRoomWallCollider,
    infoStationModel,
    salzKristall,
    imperfectionRoughMap,
    clearGlasMaterial,
    blackGlasMaterial,
    blueGlasClear,
    themeInfoMap,
    
    doorButton,
    introMonitor,
    timesliderPodest,
    roundPodest,

    themeHandelsWegeMap,
    themeHolzarbeitMap,
    themeKlimaMap,
    themeMdrMap,
    themeMenschenMap,
    themeSalzabbauMap,
    play,
    waterNormals,

    buttonHoverMaterial,
    buttonOriginalMaterial,
    saltKristallMaterial,
    saltKristallHighlightMaterial,
    whiteEmissiveMaterial,

    secretButton,
    artHolderRound,
    VideoStationSmall,
    waterColorMap,


    ParkettColor,
    ParkettNormal,
    ParkettRough,

    wallPutzColorMap,
    wallPutzNormalMap,
    wallPutzRoughMap,

  


  
  };
};

export default GlbLoader;



