import { useEffect, useState,} from "react";
import "./InfoWindowStory.css";
import { motion, AnimatePresence } from "framer-motion";
import { stateLoadingScreen, stateEnglisch, statePlayerPositionS, stateDevice } from "../store";
import { useSnapshot } from "valtio";


const parchmentVariants = {
  hidden: {
   
    scale: 0,
    "--round-corner-top-right": "500px 500px",
    "--round-corner-top-left": "500px 500px",
    "--round-corner-bottom-left": "500px 500px",
    "--round-corner-bottom-right": "500px 500px",
    transition: { type: "spring",  duration: 1, delay: 
    1, damping: 8,
      stiffness: 20,
      // restDelta: 0.001 
    }
  },
  visible: {
   
    scale: 1,
    "--round-corner-top-right": "15px 15px",
    "--round-corner-top-left": "15px 15px",
    "--round-corner-bottom-left": "15px 15px",
    "--round-corner-bottom-right": "15px 15px",
    transition: { type: "spring",  duration: 1, delay: 
    1, damping: 8,
      stiffness: 20,
      // restDelta: 0.001 
    }
  },
};



const bodyVariants = {
 
  hidden: {
    
    "--background-color": "rgba(0, 0, 0, 0)",
    transition: { type: "spring",  duration: 800, delay: 
    1, damping: 8,
      stiffness: 20,
      // restDelta: 0.001 
    },
  },
  
  visible: {
    
    "--background-color": "rgba(0, 0, 0, 0.75)",
    transition: { type: "spring",  duration: 800, delay: 
    1, damping: 8,
      stiffness: 20,
      // restDelta: 0.001
     },
  },
};

const instructVariants = {
  hidden: {
   opacity: 0 ,
  },
  visible: {
    opacity: 1, 
  },
};
export default function InfoWindowStory({ titel, text }) {
  const [showStoryWindow, setShowStoryWindow] = useState(true);
  const [showNavyWindow, setShowNavyWindow] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleMove = () => {
      if (isDragging) {
        if(showNavyWindow === true){
        setShowNavyWindow(false);
      }
      }
    };
  
    const handleStart = (event) => {
      // Prevent default behavior to prevent unintended scrolling or zooming
      event.preventDefault();
     
      setIsDragging(true);
    
    };
  
    const handleEnd = () => {
      setIsDragging(false);
    };


    // window.addEventListener('mousemove', handleMove);
    // window.addEventListener('mousedown', handleStart);
    // window.addEventListener('mouseup', handleEnd);
  
  
    // window.addEventListener('touchmove', handleMove, );
    // window.addEventListener('touchstart', handleStart, );
    // window.addEventListener('touchend', handleEnd);
 


    // return () => {
    //   window.removeEventListener('mousemove', handleMove);
    //   window.removeEventListener('mousedown', handleStart);
    //   window.removeEventListener('mouseup', handleEnd);
  
    //   window.removeEventListener('touchmove', handleMove);
    //   window.removeEventListener('touchstart', handleStart);
    //   window.removeEventListener('touchend', handleEnd);
    // };
  }, [isDragging]);

  const handlePointerDown = () => {
    setShowStoryWindow(false);
    setShowNavyWindow(true);
  };

  const handlePointerDownNav = () => {
    setShowStoryWindow(false);
    setShowNavyWindow(true);

    setTimeout(() => {
      setShowNavyWindow(false);
    }, 5000); // Delay of 1000 milliseconds (1 second)
    stateLoadingScreen.lastInfoWindowClick = true;
    statePlayerPositionS.playerInput = true;
  };

  
  return (
    <>
      <AnimatePresence>
        {showStoryWindow && 
          <InfoWindowStoryKon onPointerDown={handlePointerDownNav} setShowNavyWindow = {setShowNavyWindow} />
        }
      </AnimatePresence>
      <AnimatePresence>
        {showNavyWindow  && <InfoNavHint onPointerDown={handlePointerDownNav} />}
      </AnimatePresence>
 
    </>
  );
}

function InfoWindowStoryKon({ onPointerDown,setShowNavyWindow }) {
  const snapIsEnglisch = useSnapshot(stateEnglisch)
  
  const [titel, setTitel] = useState("WILLKOMMEN IM VIRTUELLEN MUSEUM SALZKAMMERGUT");
  const [text, setText] = useState(
    "14 „Salzzeit Museen“ zeigen den Salzreichtum der Gegend, die Entstehung des Salzes, dessen Abbau durch die Jahrtausende und die wichtige Holzarbeit. Erfahre in diesen Museen auch mehr über Kulturgeschichte und Ökologie im Salzkammergut.  22 Museen zeigen die Kraft der Region: „Die Museen der Region“.  Unsere Sammlung beinhaltet:  42 Filme, 107 3D-Objekte, 120 Bilder oder Fotos, 8 Schautafeln, 7 3D-Animationen, 36 Kugelpanoramen sowie 250 Textinformationen und weiterführende Links."
  );



useEffect(()=>{
if(snapIsEnglisch.isEnglisch)
{

  setTitel("START YOUR JOURNEY!")
  setText( "The museums of the Salzkammergut await your visit. Salzzeit.at connects the museums, stories and history. ")
}else{

  setTitel("BEGINNE DEINE REISE!")
  setText("Die Museen des Salzkammerguts warten auf Deinen Besuch. Salzzeit.at verbindet die Museen, Geschichten und Geschichte.")}


},[snapIsEnglisch.isEnglisch])

  const handleMouseDown = () => {
    onPointerDown();

  };

  return (
    <>
  
      <motion.div
      key={"background"}
      className="background"
        onPointerDown={handleMouseDown}
        variants={bodyVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
  

  
        <motion.div
        key={"parchment"}
          className="parchment"
          variants={parchmentVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          // transition={{ type: "spring", duration: 2, delay: 3 }}
        >
          <div className= "TitelDiv">{titel}</div>
          <div className= "TextDiv">{text} </div>
          {/* <div className="imageDiv"><img src="./UI/StoryButton.png"></img></div> */}
          <button className="nextButton"> next </button>
        </motion.div>


        <motion.div className="orangeDivLeft"
    key={"orangeDivLeft"}
    initial={{ x: 0,  }}
    
        animate={{  x: "-60%" , }}
        exit={{  x: -1500, transition: { delay: 0.3, type: "spring",  damping: 7,
          stiffness: 20,
          duration: 1,
         restDelta: 0.001 }   }}
        transition={  { type: "spring",  damping: 7,
          stiffness: 20,
          duration: 1,
         restDelta: 0.001,
         delay: 
        2,
        }}
        ></motion.div>

<motion.div className="orangeDivRight"
     key={"orangeDivRight"}
    initial={{ x: 0 }}
        animate={{  x: "60%"}}
        exit={{  x: 1500, transition: { delay: 0.3, type: "spring",  damping: 7,
          stiffness: 20,
          duration: 1,
         restDelta: 0.001 } }}
        transition={ {x: { type: "spring", damping: 7,
          stiffness: 20,
          duration: 1,
         restDelta: 0.001,
         delay: 
        2,
        }}}
        ></motion.div>
      </motion.div>
     
      {/* <div className="inkTitle">"Herzlicsssssssssssssssssssssssssch"</div> */}
      {/* <svg>
  <filter id="wavy2">
    <feTurbulence x="0" y="0" baseFrequency="0.02" numOctaves="5" seed="1" />
    <feDisplacementMap in="SourceGraphic" scale="10" />
  </filter>
</svg> */}

    </>
  );
}

function InfoWindowNaviKon({onPointerDown}) {

  const snapIsEnglisch = useSnapshot(stateEnglisch)
  const [url, setUrl] = useState()
  const [titel, setTitel] = useState("BEGINNE DEINE REISE!");
  const [text, setText] = useState(
    "Die Museen warten auf Deinen Besuch. Sowohl virtuell als auch vor Ort.  Hier erfährst Du alles zum Salz und zur Kulturgeschichte im Kammergut. Anklicken und Du siehst, wann das Museum geöffnet ist und wo es liegt. Salzzeit.at verbindet die Museen, Geschichten und Geschichte.  Aber zuerst: Lies die App-Anleitung links im Video. Und dann beginne Deine Reise!"
  );



  useEffect(()=>{
    if(snapIsEnglisch.isEnglisch)
    {
      setUrl("https://streamable.com/e/vjayga?loop=0")
      setTitel("START YOUR JOURNEY!")
      setText( "The museums of the Salzkammergut await your visit. Salzzeit.at connects the museums, stories and history. Please read the instructions in the video on the left first and then start your journey!")
    }else{
      setUrl("https://streamable.com/e/2m58tb?loop=0")
      setTitel("BEGINNE DEINE REISE!")
      setText( "Die Museen des Salzkammerguts warten auf Deinen Besuch. Salzzeit.at verbindet die Museen, Geschichten und Geschichte.Lies bitte zuerst die Anleitung links im Video und dann beginne Deine Reise! ")
    
    }
  },[snapIsEnglisch.isEnglisch])

  const handleMouseDown = () => {
    // statedynamicLight.videoLight = false
      onPointerDown();
  }

  return (
    <>
      <motion.div
       key={"background"}
      className="background"
    
        variants={bodyVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
  



        <motion.div
         key={"parchment"}
          className="parchment"
          initial={{ x: 1500, opacity: 0 }}
        animate={{  x: "60%",opacity: 1 }}
        exit={{  x: 1500, duration: 1, opacity: 0, delay:  0 }}
        transition={{ type: "spring", duration: 80, delay: 
        1, damping: 7,
          stiffness: 20,
          restDelta: 0.001 }}
        >
          <h1>{titel}</h1>
          <div className= "TextDiv"> {text} </div>
        
          {/* <div className="imageDiv"><img src="./UI/StoryButton.png"></img></div> */}
          <button className="nextButton"   onPointerDown={handleMouseDown}> next </button>
        </motion.div>


        <motion.div className="orangeDivLeftDown"
    key={"orangeDivLeftDown"}
    initial={{ x: -1300, opacity: 1}}
        animate={{  x: "100%",opacity: 1  }}
        exit={{  x: -1300, duration: 1,opacity: 0, delay:  0 }}
        transition={{ type: "spring", duration: 80, delay: 
        1, damping: 7,
          stiffness: 20,
          restDelta: 0.001}}
        ></motion.div>

          <motion.div className= "iFrameTutorial"
          key={"iFrameTutorial"}
           initial={{ x: -3500 , opacity: 1}}
        animate={{  x: "-25%" , opacity: 1 }}
        exit={{  x: -3500, duration: 1, delay:  0, opacity: 0 }}
        transition={{ type: "spring",  duration: 80, delay: 
        1, damping: 7,
          stiffness: 20,
          restDelta: 0.001 }}>

<iframe
    className="iframe"
    src= {url}
     style={{ width: "50vw", height: "50vh" }}
    title="your_iframe_title_here"
    
  ></iframe>
          </motion.div>
      </motion.div>

      {/* <div className="inkTitle">"Herzlicsssssssssssssssssssssssssch"</div> */}
      {/* <svg>
    <filter id="wavy2">
      <feTurbulence x="0" y="0" baseFrequency="0.02" numOctaves="5" seed="1" />
      <feDisplacementMap in="SourceGraphic" scale="10" />
    </filter>
  </svg> */}
    </>
  );
}

function InfoNavHint({onPointerDown}) {

  const snapIsEnglisch = useSnapshot(stateEnglisch)
  const [url, setUrl] = useState()
  const [titel, setTitel] = useState("Press the left mouse button and drag the mouse in the desired direction to walk");
  const [text, setText] = useState(
    "Die Museen warten auf Deinen Besuch. Sowohl virtuell als auch vor Ort.  Hier erfährst Du alles zum Salz und zur Kulturgeschichte im Kammergut. Anklicken und Du siehst, wann das Museum geöffnet ist und wo es liegt. Salzzeit.at verbindet die Museen, Geschichten und Geschichte.  Aber zuerst: Lies die App-Anleitung links im Video. Und dann beginne Deine Reise!"
  );
  const snapStateDevice = useSnapshot(stateDevice)

  useEffect(()=>{
    if(!snapStateDevice.isMobile){
    if(snapIsEnglisch.isEnglisch)
    {
      setUrl("https://streamable.com/e/vjayga?loop=0")
      setTitel("Press the left mouse button and drag the mouse in the desired direction to walk")
      setText( "The museums of the Salzkammergut await your visit. Salzzeit.at connects the museums, stories and history. Please read the instructions in the video on the left first and then start your journey!")
    }else{
      setUrl("https://streamable.com/e/2m58tb?loop=0")
      setTitel("Drücke die linke Maustaste und ziehe mit der Maus in die gewünschte Richtung, um zu gehen.")
      setText( "Die Museen des Salzkammerguts warten auf Deinen Besuch. Salzzeit.at verbindet die Museen, Geschichten und Geschichte.Lies bitte zuerst die Anleitung links im Video und dann beginne Deine Reise! ")
    
    }
  }else if(snapStateDevice.isMobile){
    if(snapIsEnglisch.isEnglisch)
    {
      setUrl("https://streamable.com/e/vjayga?loop=0")
      setTitel("Use the joystick in the left-hand corner and drag it in the direction you want to go")
      setText( "The museums of the Salzkammergut await your visit. Salzzeit.at connects the museums, stories and history. Please read the instructions in the video on the left first and then start your journey!")
    }else{
      setUrl("https://streamable.com/e/2m58tb?loop=0")
      setTitel("Benutze den Joystick in der linken Ecke um dich zu bewegen")
      setText( "Die Museen des Salzkammerguts warten auf Deinen Besuch. Salzzeit.at verbindet die Museen, Geschichten und Geschichte.Lies bitte zuerst die Anleitung links im Video und dann beginne Deine Reise! ")
    
    }


  }
  },[snapIsEnglisch.isEnglisch])

  const handleMouseDown = () => {
    // statedynamicLight.videoLight = false
      onPointerDown();
  }

  return (
    <>
      <motion.div
       key={"orangeHint"}
      className="orangeHint"
    
      initial={{ x: 2000, opacity: 0 }}
        animate={{  x: "250%",opacity: 1 }}
        exit={{  x: 2000, duration: 0, opacity: 1, delay:  0 }}
        transition={{ type: "spring", duration: 80, delay: 
        1, damping: 7,
          stiffness: 20,
          restDelta: 0.001 }}
      >
  




     
          <div className="MoveHint">{titel}</div>
          {/* <p>{text} </p> */}
        
          {/* <div className="imageDiv"><img src="./UI/StoryButton.png"></img></div> */}
          {/* <button className="nextButton"   onPointerDown={handleMouseDown}> next </button> */}
    

          {/* <motion.div className= "iFrameTutorial"
          key={"iFrameTutorial"}
           initial={{ x: -3500 , opacity: 1}}
        animate={{  x: "-25%" , opacity: 1 }}
        exit={{  x: -3500, duration: 1, delay:  0, opacity: 0 }}
        transition={{ type: "spring",  duration: 80, delay: 
        1, damping: 7,
          stiffness: 20,
          restDelta: 0.001 }}>

<iframe
    className="iframe"
    src= {url}
     style={{ width: "50vw", height: "50vh" }}
    title="your_iframe_title_here"
    
  ></iframe>
          </motion.div>  */}
      </motion.div>

      {/* <div className="inkTitle">"Herzlicsssssssssssssssssssssssssch"</div> */}
      {/* <svg>
    <filter id="wavy2">
      <feTurbulence x="0" y="0" baseFrequency="0.02" numOctaves="5" seed="1" />
      <feDisplacementMap in="SourceGraphic" scale="10" />
    </filter>
  </svg> */}
    </>
  );
}



