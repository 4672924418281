import "./HeaderStyle.css";
import { motion } from "framer-motion";
import { statePlayerSwitch } from "../store";
import { useEffect, useState } from "react";
import { statePlayerPositionS } from "../store";
import { stateOrbitLocation, stateInAreaRooms, stateDevice, stateInArea, stateEnglisch } from "../store";
import { useSnapshot } from "valtio";
import { stateSound } from "../store";
import TransparentWindow from "./TransparentWindow";
 import Sound from "../Utils/Sound";
 import * as THREE from "three";

export default function Header() {
  return <HeaderKon />;
}

function HeaderKon() {
  const [charClicked, setCharClicked] = useState(false);
  const [audioClicked, setAudioClicked] = useState(true);
const snapStateEnglisch = useSnapshot(stateEnglisch)
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isFp, setIsFp] = useState(false);
  const snapOrbitLoaction = useSnapshot(stateOrbitLocation)
  const snapPlayerSwitch = useSnapshot(statePlayerSwitch)
  const snapInAreaRooms = useSnapshot(stateInAreaRooms)
  const snapStateDevice = useSnapshot(stateDevice)
  const snapStateInArea = useSnapshot(stateInArea)
  const snapStateSound = useSnapshot(stateSound)

  const {
    playClickSound,
  
    playMouseIn,
    playMouseOut
  } = Sound();

  function handleCharClick() {
    playClickSound()
    if (!statePlayerSwitch.charSwitchClicked && !stateOrbitLocation.lerpRuns ) {
      statePlayerSwitch.charSwitchClicked = true;
      setCharClicked(true);
    } else if(!stateOrbitLocation.lerpRuns) {
      statePlayerSwitch.charSwitchClicked = false;
      setCharClicked(false);
    }

   
  }

  useEffect(()=>{
   if(!stateOrbitLocation.lerpRuns && !snapPlayerSwitch.charSwitchClicked) {
      // statePlayerSwitch.charSwitchClicked = false;
      setCharClicked(false);
    }

  },[snapPlayerSwitch.charSwitchClicked])

  function handleFPClick() {
    setIsFp(!isFp);
    playClickSound()
    if(!isFp){

      statePlayerPositionS.fpPlayer=true
    }
    else{
      statePlayerPositionS.fpPlayer=false
    }
  }

  function handleAudioClick(e) {
    e.stopPropagation(); 
    playClickSound()
    if(snapStateSound.backAudio){
    stateSound.backAudio = false;
    setAudioClicked(false)
  }else{
    stateSound.backAudio = true;
    setAudioClicked(true)
  }
   
  }
  function toggleHome() {
    statePlayerPositionS.playerPositionS = new THREE.Vector3(0, 0, -150)
    statePlayerPositionS.homeclicked = true

  }


  function toggleFullscreen() {
    playClickSound()
    if (!isFullscreen) {
      // Request fullscreen
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen); // Toggle fullscreen state
  }


  const listItemsSalzMuseem = ["Landkarte"];
  const listItemsKulturgeschichte = [
    "Arbeitswelt",
    "Heimat",
    "Glaube",
    "Macht",
    "Gewalt",
    "Zeitmaschine Bergmann",
  ];
  
  const listItemsSalztransport = [
    "Schifffahrt",
    "Solerohre",
    "Die Städte",
    "Transport 1850",
    "Die Traun",
    "Zeitmaschine Transport",
  ];
  
  const listItemsSalzzabbau = [
    "Entstehung des Salzes",
    "Prähistorie",
    "Mittelalter und Neuzeit",
    "Moderner Bergbau",
    "Tourismus",
    "Zeitmaschine Werkzeuge",
  ];
  
  const listItemsOekologie = [
    "Bergrutsch",
    "Bergsturz",
    "Almen",
    "Eiszeitmodelle",
    "Dachstein",
  ];
  
  const listItemsHolzarbeit = [
      "Holz im Bergbau",
      "Holz im Transportwesen",
      "Werkzeuge",
      "Holzarbeit",
      "Papier",
      "Zeitmaschine Holz"
    ];







    const listItemsSalzMuseemEn = ["Map"];

    const listItemsKulturgeschichteEn = [
      "Working world",
      "Folk culture",
      "Faith",
      "The power",
      "Violence",
      "Time Machine cultural history",
    ];
    
    const listItemsSalztransportEn = [
      "Salt shipping",
      "Pipeline",
      "The cities",
      "Transport 1850",
      "Traun River",
      "Time machine transport of salt",
    ];
    
    const listItemsSalzzabbauEn = [
      "The origin of salt",
      "Prehistory",
      "Middle Ages and modern period",
      "Modern mining",
      "Tourism",
      "Time machine tools",
    ];
    
    const listItemsOekologieEn = [
      "The prehistoric landslides",
      "debris flows",
      "High alpine pastures on the Dachstein",
      "Ice Age models",
      "Dachstein",
    ];
    
    const listItemsHolzarbeitEn = [
        "Wood in mining",
        "Wood in transport",
        "Tools",
        "Woodworking",
        "Paper",
      ];




  const [open, setOpen] = useState(false);
  const [listItems, setlistItems] = useState([]);
  const [isOpenList, setIsOpenList] = useState(false);

  useEffect(() => {}, []);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleMenuSalzzeitMuseen = () => {
    // do something
    setOpen(false);
    if(snapStateEnglisch.isEnglisch)
    {
      setlistItems(listItemsSalzMuseemEn);
    }
    else{
      setlistItems(listItemsSalzMuseem);
    }
    setIsOpenList(true);
    console.log(isOpenList);
  };

  const handleMenuKulturgeschichte = () => {
    // do something
    setOpen(false);
    if(snapStateEnglisch.isEnglisch)
    {
      setlistItems(listItemsKulturgeschichteEn);
    }
    else{
      setlistItems(listItemsKulturgeschichte);
    }
    setIsOpenList(true);
  };

  const handleMenuSalztransport = () => {
    // do something
    setOpen(false);
    if(snapStateEnglisch.isEnglisch)
    {
      setlistItems(listItemsSalztransportEn);
    }
    else{
      setlistItems(listItemsSalztransport);
    }
    setIsOpenList(true);
  };

  const handleMenuDasSalz = () => {
    // do something
    setOpen(false);
    if(snapStateEnglisch.isEnglisch)
    {
      setlistItems(listItemsSalzzabbauEn);
    }
    else{
      setlistItems(listItemsSalzzabbau);
    }
    setIsOpenList(true);
  };

  const handleMenuDieÖkologie = () => {
    // do something
    setOpen(false);
    if(snapStateEnglisch.isEnglisch)
    {
      setlistItems(listItemsOekologieEn);
    }
    else{
      setlistItems(listItemsOekologie);
    }
    setIsOpenList(true);
  };

  const handleMenuDieHolzarbeit = () => {
    // do something
    setOpen(false);
    if(snapStateEnglisch.isEnglisch)
    {
      setlistItems(listItemsHolzarbeitEn);
    }
    else{
      setlistItems(listItemsHolzarbeit);
    }
    setIsOpenList(true);
  };

  const handleOnMouseOut = () => {
    // do something
    setOpen(false);

  };


  return (
    <>
      <TransparentWindow
        listItems={listItems}
        setIsOpenList={setIsOpenList}
        isOpenList={isOpenList}
      ></TransparentWindow>
    <motion.div
      className="container"
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", duration: 1.8, delay: 2 }}
    >

      <header className="header">
        {/* Logo */}
        <div className="logoContainerHUDOver">
        <div className="logoContainerHUD">
          <img
            className="museumIconHUD"
            src="../UI/IconMsueumHeader.svg"
            alt="Museum Icon"
          />
          <div className="logoHUD">SALZZEIT</div>
          <div className="logoSentenceHUD">Salzkammergut Connected History</div>
          
         
      
          
   
        </div>
        <img
            className="badIschlIconHUD"
            src="../Logos/Badischl.png"
            alt="Museum Icon"
          />
        </div>
        {/* Sidemenu */}
        
        
<div className="sideMenuContainer"    onMouseLeave={handleOnMouseOut}>

{!snapOrbitLoaction.orbitClicked && <div className="DropdownContainer"
   
    >
        <button className="buttonDrop" onClick={handleOpen}>
          {snapStateEnglisch.isEnglisch ? "FAST TRACK" : "SCHNELLREISE"}
        </button>
        {open ? (
            <div >
          <ul className="menu" >
            <li className="menu-item">
              <button onClick={handleMenuSalzzeitMuseen}>
                {snapStateEnglisch.isEnglisch ? "The Salzzeit Museums" : "Salzzeit Museen"}
              </button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuKulturgeschichte}>
              {snapStateEnglisch.isEnglisch ? "The cultural history": "Die Kulturgeschichte"}
              </button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuSalztransport}>
              {snapStateEnglisch.isEnglisch ? "The transport of salt" : "Der Salztransport"}
              </button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuDasSalz}>
              {snapStateEnglisch.isEnglisch ? "The salt" : "Das Salz" }
              </button>
            </li>

            <li className="menu-item">
              <button onClick={handleMenuDieÖkologie}>
              {snapStateEnglisch.isEnglisch ? "The ecology" : "Die Ökologie" }
              </button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuDieHolzarbeit}>
              {snapStateEnglisch.isEnglisch ? "The woodwork" : "Die Holzarbeit"}
              </button>
            </li>
          </ul>
          </div>
        ) : null}
 
      </div>}


{!snapStateInArea.inArea && <div className={`circleHeader ${audioClicked ? "clicked" : ""}`}>
            <img
              className="headerButton"
              src="UI/AudioButton2_.png"
              alt="Audio"
              onClick={(e) => handleAudioClick(e)}
              onMouseDown
            />
          </div>}

 
     {  !snapStateDevice.isIphone &&  <div
            className={`circleHeader ${isFullscreen ? "clicked" : ""}`}
            onClick={toggleFullscreen}
          >
            <img
              className="headerButton"
              src="UI/FullscreenIcon_.png"
              alt="FullScreen"
            />
          </div>}
        
      {  !snapOrbitLoaction.orbitClicked && !snapPlayerSwitch.charSwitchClicked &&  <div
            className={`circleHeader ${isFp ? "clicked" : ""}`}
            onClick={handleFPClick}
          >
            <img
              className="headerButton"
              src="UI/FPButton_.png"
              alt="FirstPerson"
            />
          </div>}

          { !snapOrbitLoaction.orbitClicked && snapInAreaRooms.inAreaRoomEntryRoom &&  <div
            className={`circleHeader ${charClicked ? "clicked" : ""}`}
            onClick={handleCharClick}
          >
            <img
              className="headerButton"
              src="UI/CharButton2_.png"
              alt="Character"
            />
          </div>}
          {  !snapOrbitLoaction.orbitClicked && !snapPlayerSwitch.charSwitchClicked &&  <div
            className={`circleHeader`}
            onClick={toggleHome}
          >
            <img
              className="headerButton"
              src="UI/HomeIcon_.png"
              alt="Home"
            />
          </div>}
        </div>
      </header>
    </motion.div>
    </>
  );
}
