import { stateSound } from "../store";
import { useSnapshot } from "valtio";
import useSound from "use-sound";
import { useEffect, useState} from "react";

function SoundBackground() {

    const snapStateSound = useSnapshot(stateSound)

    const [prevAudio, setPrevAudio] = useState(false);
    const [play, { pause }] = useSound("/Sounds/Relax_short.mp3", {
       volume: 0.5,
       
        autoplay: true, // Set autoplay to true
        loop: true, // Set loop to true
      });


      useEffect(()=>{

        if (snapStateSound.backAudio) {
            play();
            setPrevAudio(snapStateSound.backAudio)
            // stateSound.backAudio = true
          } else {
            pause();
            setPrevAudio(snapStateSound.backAudio)
            // stateSound.backAudio = false
          }


      },[snapStateSound.backAudio])



      useEffect(() => {
        // Effect to handle visibility change
        const handleVisibilityChange = () => {
          setPrevAudio(snapStateSound.backAudio)
          if (document.visibilityState === "visible") {
            // When document becomes visible
            if (prevAudio) {
              play(); // Play audio if prevAudio is true
            
            } else {
              pause(); // Pause audio if prevAudio is false
   
            }
          } else {
          
            // When document becomes hidden
            pause(); // Pause audio
          }
        };
    
        // Add event listener for visibility change
        document.addEventListener("visibilitychange", handleVisibilityChange);
    
        // Cleanup function to remove event listener
        return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
      }, [prevAudio, play, pause]);

  return null
}


  


export default SoundBackground;
