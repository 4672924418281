import { useEffect, useState } from "react";
import { stateDevice } from "../store";
import { useSnapshot } from "valtio";
import "./CheckLandscapeMode.css";




function CheckLandscapeMode() {
  const snapStateDevice = useSnapshot(stateDevice)
    const [screenOrientLandscape, setScreenOrientLandscape] = useState(
        window.matchMedia("(orientation: landscape)").matches
      );
    
      useEffect(() => {
        const handleOrientationChange = () => {
          const isLandscape = window.matchMedia("(orientation: landscape)").matches;
          setScreenOrientLandscape(isLandscape);
        };
    
        // Listen for changes in the media query
        const mediaQueryList = window.matchMedia("(orientation: landscape)");
        mediaQueryList.addEventListener("change", handleOrientationChange);
    
        // Clean up the event listener when the component unmounts
        return () => {
          mediaQueryList.removeEventListener("change", handleOrientationChange);
        };
      }, []);
    
      return snapStateDevice.isMobile && !screenOrientLandscape ? (
        <>
          <div className="RoomLoaderBack">
          <img
       
          className="turnDeviceImage"
          src="UI/turnDevices.svg"
          alt="turnDevice"
          
        />
            <div className="text">
            Please turn Device in 
            Landscape Mode</div>
            
          </div>
        </>
      ) : null;
    }
    

export default CheckLandscapeMode;
