








// Function to dispose of GLTF scenes, including textures
export function disposeGLTF(scenes) {
  scenes.forEach(scene => {
    scene.traverse(child => {
      if (child.isMesh) {
        // Dispose of geometry
        if (child.geometry) {
          child.geometry.dispose();
        }

        // Dispose of materials and their textures
        if (Array.isArray(child.material)) {
          // If the material is an array (multi-materials), dispose each one
          child.material.forEach(material => disposeMaterialAndTextures(material));
        } else {
          // Single material
          disposeMaterialAndTextures(child.material);
        }
      }

      // Optionally dispose of animations if present and not needed anymore
      if (child.isObject3D && child.animations) {
        child.animations.forEach(animation => {
          animation.dispose();
        });
      }
    });
  });
};

// Helper function to dispose of materials and their associated textures
function disposeMaterialAndTextures(material) {
  // Dispose of textures
  if (material.map) material.map.dispose();
  if (material.lightMap) material.lightMap.dispose();
  if (material.bumpMap) material.bumpMap.dispose();
  if (material.normalMap) material.normalMap.dispose();
  if (material.displacementMap) material.displacementMap.dispose();
  if (material.specularMap) material.specularMap.dispose();
  if (material.emissiveMap) material.emissiveMap.dispose();
  if (material.alphaMap) material.alphaMap.dispose();
  if (material.roughnessMap) material.roughnessMap.dispose();
  if (material.metalnessMap) material.metalnessMap.dispose();
  if (material.envMap) material.envMap.dispose();

  // Finally, dispose of the material itself
  material.dispose();
}

// export function disposeGLTF(scenes) {
//   scenes.forEach(scene => {
//     scene.traverse(child => {
//       if (child.isMesh) {
//         child.geometry.dispose();
//         child.material.dispose();
//       }
//       if (child.isObject3D && child.animations) {
//         child.animations.forEach(animation => {
//           animation.dispose();
//         });
//       }
//     });
//   });
// };