import * as THREE from 'three'

export default [
{
	Id: 'InfoPointMonitor001',
	Titel: 'InfoPointMonitor001',
	Position: new THREE.Vector3 (-32.6714, 12.3634, 0.0),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'InfoPointMonitor002',
	Titel: 'InfoPointMonitor002',
	Position: new THREE.Vector3 (-16.3357, 12.3634, -28.2942),
	Rotation: new THREE.Vector3 (0.0,0.0,60.0)
},
{
	Id: 'InfoPointMonitor003',
	Titel: 'InfoPointMonitor003',
	Position: new THREE.Vector3 (16.3357, 12.3634, -28.2942),
	Rotation: new THREE.Vector3 (0.0,0.0,120.0)
},
{
	Id: 'InfoPointMonitor004',
	Titel: 'InfoPointMonitor004',
	Position: new THREE.Vector3 (32.6714, 12.3634, 2.85622e-06),
	Rotation: new THREE.Vector3 (0.0,0.0,-180.0)
},
{
	Id: 'InfoPointMonitor005',
	Titel: 'InfoPointMonitor005',
	Position: new THREE.Vector3 (16.3357, 12.3634, 28.2942),
	Rotation: new THREE.Vector3 (0.0,0.0,-120.0)
},
{
	Id: 'InfoPointMonitor006',
	Titel: 'InfoPointMonitor006',
	Position: new THREE.Vector3 (-16.3357, 12.3634, 28.2942),
	Rotation: new THREE.Vector3 (0.0,0.0,-60.0)
},
]