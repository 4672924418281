
import "./CloseButtonLowModelOrbit.css"
import { statePlayerSwitch } from '../store';

const CloseButtonLowCharacterSwitch = () => {
  

  const handleClose = () => {
    statePlayerSwitch.charSwitchClicked = false

   
  };

  return (
    <>
    
       
    
            <button className="closeButtonLowModel" onPointerDown={handleClose}>
            close character switch
            </button>
      
      
     
  
    </>
  );
};

export default CloseButtonLowCharacterSwitch;