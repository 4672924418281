



export const FontLoader = () => {

    const topTitel = {
       
        font: "/Fonts/Roboto-Thin-webfont.woff",
      
        letterSpacing: 0,
        lineHeight: 1,
        "material-toneMapped": false,
        fontSize: 10,
        anchorX: "center",
        anchorY: "center",
        color: "white",
        // characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
      };

    const titelFont = {
        font: "/Fonts/Roboto-Regular-webfont.woff",
       
      
        letterSpacing: -0.05,
        lineHeight: 1,
        "material-toneMapped": false,
        fontSize: 6.5,
        anchorX: "left",
        anchorY: "top",
        
    
        // characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
      };

      const eingangFont = {
        font: "/Fonts/Roboto-Regular-webfont.woff",
       
      
        letterSpacing: 0.1,
        lineHeight: 1,
        "material-toneMapped": false,
        fontSize: 5,
        anchorX: "center",
        anchorY: "center",
        
    
        // characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
      };
      const titelFontEntryRoom = {
        font: "/Fonts/Roboto-Regular-webfont.woff",
       
      
        letterSpacing: -0.05,
        lineHeight: 1,
        "material-toneMapped": false,
        fontSize: 6.5,
        anchorX: "right",
        anchorY: "top",
        
    
        // characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
      };

      const wayFont = {
        font: "/Fonts/Roboto-Regular-webfont.woff",
       
      
        letterSpacing: 0,
        lineHeight: 1,
        // "material-toneMapped": false,
        fontSize: 3,
        anchorX: "left",
        anchorY: "top",
        
    
        // characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
      };

      

      const panoFont = {
        font: "/Fonts/Roboto-Regular-webfont.woff",
       
      
        letterSpacing: 0,
        lineHeight: 1,
        "material-toneMapped": false,
        fontSize: 3,
        anchorX: "center",
        anchorY: "center",
        color: "#ffa200"
        
    
        // characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
      };
      
      const titelSecondFont = {
        font: "/Fonts/Roboto-Thin-webfont.woff",
      
        letterSpacing: 0,
        lineHeight: 1.4,
        "material-toneMapped": false,
        fontSize: 4,
        anchorX: "left",
        anchorY: "top",
        
        // characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
      };

      const titelVideoFont = {
        font: "/Fonts/Roboto-Thin-webfont.woff",
      
        letterSpacing: 0,
        lineHeight: 1.4,
        "material-toneMapped": false,
        fontSize: 1,
        anchorX: "center",
        anchorY: "top",
        
        // characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
      };
      
      const themeFont = {
        font: "/Fonts/Roboto-Thin-webfont.woff",
      
        letterSpacing: -0.05,
        lineHeight: 0,
        "material-toneMapped": false,
        fontSize: 2.5,
        anchorX: "left",
        anchorY: "top",
        // characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
      }

  return {

   
   
    titelFont,
    titelSecondFont,
    themeFont,
    topTitel,
    wayFont,
    panoFont,
    titelFontEntryRoom,
    titelVideoFont,
    eingangFont

  }

};

export default FontLoader;









