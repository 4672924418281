import "./impressum.css"
import { stateIframeWindow,statePlayerPositionS } from '../store';

const Impressum = () => {
  

  const handleClick = () => {
    stateIframeWindow.showIframe = true
    stateIframeWindow.src = "./impressum/index.html"
    statePlayerPositionS.playerInput= false
  
  };

  return (
    <>
    
        <div className="impressumText" onClick={handleClick}>
        impressum
        </div>
  
    </>
  );
};

export default Impressum;