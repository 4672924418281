import { useAnimations, Shadow} from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useRef, useEffect,useState} from "react";
import { Euler } from "three";
import GlbLoader from "../Utils/GlbLoader.jsx";
import * as THREE from "three";
import useMouseInput from "../hooks/useMouseInput.jsx";
import { useSnapshot } from "valtio";
import {
  statePlayerPositionS,
  statePlayerSwitch,
  stateDevice,
  stateTouchCircle,
  stateTouchCircleLook,
  stateOrbitLocation,
  stateIframeWindow,
  stateLoadingScreen
} from "../store.jsx";
import { RigidBody, CapsuleCollider, vec3} from "@react-three/rapier";
import { usePlayerPosition } from "../Utils/PlayerPositionContext";



// const quat = useMemo(() => new Quaternion(), [])

const currentLookat = new THREE.Vector3();
const timeDilation = 0.28; //50
let rotationAngle = 0;
let rotationSpeed = 6.5;
const threshold = 0
const damping = 0.1  // Adjust the threshold value as desired
const dampingMobile = 0 // Adjust the threshold value as desired
// const playerRun = false;
let rigidBodyPlayerVec = vec3();
let rotationFactor
let xPos
let zPos
let xPosLook, zPosLook;
// let cameraSavedPosition
const steepness = 10; // Adjust as needed
const backwardThreshold = -0.32
// const backwardThreshold = -0.1;

const bottomThreshold = -0.1; // Adjust the threshold as needed
const topThreshold = 0; // Adjust the threshold as needed
let idealOffset = new THREE.Vector3(2, 25, -35);
let idealLookat = new THREE.Vector3(0, 20, 0);

let impulse = new THREE.Vector3();
let speedFactor;
const quaternionRotation = new THREE.Quaternion();
const velocity = new THREE.Vector3();
let forwardDirection = new THREE.Quaternion();
let x = 0;
let y = 0;
let z = 0;
const cameraOffset = new THREE.Vector3();
const rigidBodyQuat = new THREE.Quaternion();
let horizontalAngle = 0;
let verticalAngle = 0;
const rigidBodyVec = vec3();
let adjustedZPos;
let verticalAngleMin = 0;
let verticalAngleMax = 0;
let rotatedCameraOffset = new THREE.Quaternion();
let elapsedTime = 0;
let t = 0.03;
let speed = 0
let action

export default function Player() {

  const snapPlayerPosition = useSnapshot(statePlayerPositionS);

  const [reloadKey, setReloadKey] = useState(0);

  const togglePlayer = () => {

    setPlayerWomen((prevPlayerWomen) => !prevPlayerWomen);
    // Incrementing the reload key to force a re-render
    setReloadKey((prevKey) => prevKey + 1);
};



useEffect(()=>{
if(snapPlayerPosition.homeclicked)
{
  togglePlayer()
}
},[snapPlayerPosition.homeclicked])

  const [playerWomen, setPlayerWomen] = useState(false);
  return (
    <>
      {playerWomen ? (
        <PlayerWoman key={reloadKey} setPlayerWomen={setPlayerWomen}></PlayerWoman>
      ) : (
        <PlayerMale key={reloadKey} setPlayerWomen={setPlayerWomen}></PlayerMale>
      )}
    </>
  );
}

function PlayerMale({ setPlayerWomen }) {
  const { playerModel } = GlbLoader();

  const { actions, name } = useAnimations(
    playerModel.animations,
    playerModel.scene
  );

  return (
    <PlayerKon
      model={playerModel.scene}
      actions={actions}
      name={name}
      setPlayerWomen={setPlayerWomen}
    ></PlayerKon>
  );
}

function PlayerWoman({ setPlayerWomen }) {
  const { playerModelWoman } = GlbLoader();

  const { actions, name } = useAnimations(
    playerModelWoman.animations,
    playerModelWoman.scene
  );

  return (
    <PlayerKon
      model={playerModelWoman.scene}
      actions={actions}
      name={name}
      setPlayerWomen={setPlayerWomen}
    ></PlayerKon>
  );
}

let currentPosition = new THREE.Vector3(0, 20, -180);

function PlayerKon({ actions, model, name, setPlayerWomen }) {

  // const euler = useMemo(() => new Euler(), []);
  // const speed = { value: 150, min: 100, max: 1000, step: 10 };
let euler = new Euler()

// let currentRigidBodyPosition = new THREE.Vector3(0, 0, -150);
  const snapisMobile = useSnapshot(stateDevice);
  const snapTouchCircle = useSnapshot(stateTouchCircle);
  const snapTouchCircleLook = useSnapshot(stateTouchCircleLook);
  const snapSwitchPlayer = useSnapshot(statePlayerSwitch);
  const snapOrbitLocation = useSnapshot(stateOrbitLocation);
  const snapPlayerPosition = useSnapshot(statePlayerPositionS);
  const snapIframeWindow = useSnapshot(stateIframeWindow);
  const snapstateLoadingScreen = useSnapshot(stateLoadingScreen)
  let currentRigidBodyPosition = snapPlayerPosition.playerPositionS

  const [lookJoyOn, setLookJoyOn] = useState(false)

  const playerRef = useRef();
  const playerBodyRef = useRef();

  //   quaternionRotation.setFromEuler(snapPlayerPosition.playerRotationS);
//   // playerBodyRef.current.setRotation(snapPlayerPosition.playerRotationS);
//   playerBodyRef.current.setRotation(quaternionRotation);

useEffect(()=>{
if(snapTouchCircleLook.JoystickLookOn){
  setLookJoyOn(true)
}else{
  setLookJoyOn(false)
}

},[snapTouchCircleLook.JoystickLookOn, snapTouchCircleLook.OrbitOn])

  useEffect(()=>{
    if(!snapSwitchPlayer.charSwitchClicked){
  rotationAngle = 1.65;
  rotationAngle = snapPlayerPosition.playerRotationS.y
  quaternionRotation.setFromEuler(snapPlayerPosition.playerRotationS);
  // playerBodyRef.current.setRotation(snapPlayerPosition.playerRotationS);
  playerBodyRef.current.setRotation(quaternionRotation);

} else return
},[snapstateLoadingScreen.lastInfoWindowClick])


  const { setPlayerPosition, setCamTarget,setPlayerQuat } = usePlayerPosition();

  useEffect(() => {
    playerBodyRef.current.setRotation(quaternionRotation);
    currentRigidBodyPosition.copy(rigidBodyPlayerVec);
    // playerRef.current.rotation.copy(rigidBodyQuat)
    // rigidBodyQuat.copy(playerBodyRef.current.rotation());
    if (snapSwitchPlayer.playerWoman) {
      setPlayerWomen(true);
    } else {
      setPlayerWomen(false);
    }
    let action = "idle";
    const nextActionToPlay = actions[action];
    nextActionToPlay?.reset().play();
  }, [snapSwitchPlayer.playerWoman]);

  useEffect(() => {
    if(snapPlayerPosition.homeclicked){
      statePlayerPositionS.homeFinished = false
      statePlayerPositionS.homeclicked = true
     
      statePlayerPositionS.playerPositionS = new THREE.Vector3(0, 0, -150);
    // currentRigidBodyPosition = new THREE.Vector3(0, 0, -150);
   currentPosition = new THREE.Vector3(0, 20, -180);

   const delayTimeout = setTimeout(() => {


    statePlayerPositionS.homeclicked = false;
    statePlayerPositionS.homeFinished = true
  }, 2000); 

   
     return () => clearTimeout(delayTimeout);

  }
  }, [snapPlayerPosition.homeclicked]);


  useEffect(() => {
    if(snapPlayerPosition.travelclicked){
      statePlayerPositionS.travelclicked = true
     
     rotationAngle=snapPlayerPosition.playerRotationS.y
    currentRigidBodyPosition.copy(snapPlayerPosition.playerPositionS)
   currentPosition.copy(snapPlayerPosition.playerPositionS);
 
  quaternionRotation.setFromEuler(snapPlayerPosition.playerRotationS);
  // playerBodyRef.current.setRotation(snapPlayerPosition.playerRotationS);
  playerBodyRef.current.setRotation(quaternionRotation);
  
  // forwardDirection.copy(snapPlayerPosition.playerRotationS);
  // impulse.applyQuaternion(forwardDirection);
  // velocity.set(impulse.x, impulse.y, impulse.z);
  // rigidBodyQuat.copy(snapPlayerPosition.playerRotationS)
  // rigidBodyVec.copy(playerBodyRef.current.translation());


   const delayTimeout = setTimeout(() => {


    statePlayerPositionS.travelclicked = false;
  }, 2000); // Delay of 1000 milliseconds (1 second), adjust as needed

     // Clean up the timeout to avoid memory leaks
     return () => clearTimeout(delayTimeout);

  }
  }, [snapPlayerPosition.travelclicked]);


  // const regress = useThree((state) => state.performance.regress)

  let newCameraPosition = new THREE.Vector3();


  actions.name = name;


  const currentAction = useRef("");

  // const canvas = useThree((state)=> state.controls)
  const camera = useThree((state) => state.camera);
  const stateTemp = { startInteract: true };

  let { mouse } = useMouseInput(stateTemp);

  const [cameraSavedTarget, setCameraSavedTarget] = useState(
    new THREE.Vector3()
  );
  const [cameraStill, setCameraStill] = useState(new THREE.Vector3());
  const [distanceToTarget, setDistanceToTarget] = useState();

  // Access the mouse coordinates from the hook

  useEffect(() => {
    if (snapTouchCircleLook.OrbitOn) {
      camera.position.copy(cameraStill);
      currentPosition.copy(CalculateIdealOffset());
      const currentLookAt = CalculateIdealLookat();
      const distanceToTarget = currentPosition.distanceTo(currentLookat);
      setCameraSavedTarget(currentLookAt);
      setDistanceToTarget(distanceToTarget);

    }
  }, [snapTouchCircleLook.OrbitOn]);

  useEffect(() => {
    if (snapPlayerPosition.fpPlayer) {
      // idealOffset.set(0, 20, 0)
      t = 0.1;
    } else {
      // idealOffset.set(2, 25, -35)
      t = 0.08;
    }
  }, [snapPlayerPosition.fpPlayer]);

  useEffect(()=>{

if (snapOrbitLocation.lerpRuns){
  action = "idle";
 
  setAction(action);
  }
  },[snapOrbitLocation.lerpRuns])

  // Character Walk animations
  const animate = (elapsedTime, zPos) => {

 
   speed = Math.abs(zPos);

    if (speed === 0) action = "idle"

    else if (speed < 0.75 ) action = "walk" 
    else action = "run" 
    
  

    setAction(action);

    // Synchronize the animation speed with the frame rate
    if (actions[action]) {
      actions[action].setEffectiveTimeScale(1);
      actions[action].setDuration(actions[action].getClip().duration);
      actions[action].time = elapsedTime % actions[action].getClip().duration;
    }

  };

  // useEffect(()=>{

  //   if(speed === 0){
  //     document.body.style.cursor = "auto";

  //   }else if (speed < 0.75 ){

  //     document.body.style.cursor = "none";
  //   }else if(speed > 0.75 ){

  //     document.body.style.cursor = "none";
  //   }
    

  const thresholdTurn  = 0.08
  // },[action])
  
  //Character Action blending
  const setAction = (action) => {
    if (currentAction.current != action) {
      const nextActionToPlay = actions[action];
      const current = actions[currentAction.current];
      current?.fadeOut(0.2);
      nextActionToPlay?.reset().fadeIn(0.2).play();
      currentAction.current = action;
    }
  };

  //Calculate FollowCamera
  const CalculateIdealOffset = () => {
    // console.log(snapPlayerPosition.fpPlayer)
    if (snapPlayerPosition.fpPlayer) {
      idealOffset.set(0, 20, 0);
    } else {
      idealOffset.set(2, 25, -35);
    }

    rigidBodyVec.copy(playerBodyRef.current.translation());
    rigidBodyQuat.copy(playerBodyRef.current.rotation());
    idealOffset.applyQuaternion(rigidBodyQuat);
    idealOffset.add(rigidBodyVec);

    return idealOffset;
  };

  const CalculateIdealLookat = () => {
    if (snapPlayerPosition.fpPlayer) {
      idealLookat.set(0, 20, 10);
    } else {
      idealLookat.set(0, 20, 0);
    }

    rigidBodyVec.copy(playerBodyRef.current.translation());
    rigidBodyQuat.copy(playerBodyRef.current.rotation());
    idealLookat.applyQuaternion(rigidBodyQuat);
    idealLookat.add(rigidBodyVec);

    return idealLookat;
  };




  // let walkAnimationTimeout = null;

  useEffect(() => {
    setPlayerPosition(rigidBodyPlayerVec);
    setCamTarget(currentLookat);
    setPlayerQuat(forwardDirection)
    
    playerBodyRef.current.setRotation(quaternionRotation);
  }, [rigidBodyPlayerVec, currentLookat]);

  useFrame((state, deltaTime) => {
 
    if (snapPlayerPosition.playeractive && !snapOrbitLocation.lerpRuns && playerBodyRef.current) {

      elapsedTime = state.clock.getElapsedTime();

      // state.performance.regress()
      xPosLook = snapTouchCircleLook.x;
      zPosLook = snapTouchCircleLook.y;

  

      // Set the camera's lookAt to the new look-at position
      if(snapPlayerPosition.playerInput){
      if (!snapTouchCircleLook.OrbitOn ) {
        if (!snapisMobile.isMobile && !snapTouchCircleLook.OrbitOn && !snapTouchCircle.joystickMove ) {
          xPos = mouse.current.x 
          zPos = mouse.current.y 
        } else if (snapisMobile.isMobile) {
          xPos = snapTouchCircle.x;
          zPos = snapTouchCircle.y;
        }
        else if (!snapisMobile.isMobile && snapTouchCircle.joystickMove ) {
          xPos = snapTouchCircle.x;
          zPos = snapTouchCircle.y;
        }
      }
        else
        {
          xPos = 0
          zPos = 0
        }

//         // const linvelY = playerBodyRef.current.linvel().y;



        if (zPos > backwardThreshold && Math.abs(xPos) > thresholdTurn) {
          zPos = 0;
       
        }

        if(snapisMobile.isMobile || snapTouchCircle.joystickMove ){
          rotationSpeed = 9
  
          
        }
        else{
          rotationSpeed = 6.5
        }
        
   if(!snapisMobile.isMobile && !snapTouchCircle.joystickMove){
        if (zPos >= -0.4 && zPos <= -0 && Math.abs(xPos) < thresholdTurn) {
          zPos = zPos * -3
    
        }else if(zPos >= -0.6 && zPos <= -0.3 ){
          zPos = 0
        }
        
      }


        impulse.set(xPos, 0, zPos);

        // Adjust the speed factor based on zPos

        adjustedZPos = zPos 
        if (zPos < bottomThreshold) {
          adjustedZPos = bottomThreshold;
        } else if (zPos > topThreshold) {
          adjustedZPos = topThreshold;
        }

        // Calculate the speed factor based on the adjusted zPos
        speedFactor = 220 - adjustedZPos * deltaTime; // Adjust the factor as needed

        impulse.multiplyScalar(-speedFactor * timeDilation);

        

    rotationFactor = 1 / (1 + Math.exp(-steepness * (Math.abs(xPos) - damping)));

        if (xPos < -threshold) {
       
          rotationAngle += -xPos * rotationSpeed * deltaTime * rotationFactor

          quaternionRotation.setFromEuler(new THREE.Euler(0, rotationAngle, 0));
          playerBodyRef.current.setRotation(quaternionRotation);
        } else if (xPos > threshold) {
          rotationAngle += -(xPos * rotationSpeed * deltaTime * rotationFactor );

          quaternionRotation.setFromEuler(new THREE.Euler(0, rotationAngle, 0));
          playerBodyRef.current.setRotation(quaternionRotation);
        }

        forwardDirection.copy(playerBodyRef.current.rotation());
     
        euler.y = rotationAngle;
        euler.order = "YZX";
        forwardDirection.setFromEuler(euler);
        impulse.applyQuaternion(forwardDirection);
        velocity.set(impulse.x, impulse.y, impulse.z);

        playerBodyRef.current.setLinvel(velocity);

        if (!snapTouchCircleLook.OrbitOn && xPos != 0) {
          if (lookJoyOn === true) {
            setLookJoyOn(false);
          }
        }
    

   
        animate(elapsedTime, zPos);
        // move(elapsedTime, playerPosition);
      }
      // Update the camera's look-at position based on xPosLook and zPosLook
 

      if (snapTouchCircleLook.OrbitOn) {

        rotationFactor = 1 / (1 + Math.exp(-steepness * (Math.abs(xPos) - dampingMobile)));

        if (xPosLook < -threshold) {
        rotationFactor = 1 / (1 + Math.exp(-steepness * (Math.abs(xPos) - dampingMobile)));
          rotationAngle += -xPosLook * 2 * deltaTime 

          quaternionRotation.setFromEuler(new THREE.Euler(0, rotationAngle, 0));
          playerBodyRef.current.setRotation(quaternionRotation);
        } else if (xPosLook > threshold) {
          rotationAngle += -(xPosLook * 2 * deltaTime );

          quaternionRotation.setFromEuler(new THREE.Euler(0, rotationAngle, 0));
          playerBodyRef.current.setRotation(quaternionRotation);
        }
       
        forwardDirection.copy(playerBodyRef.current.rotation());
    
        euler.y = rotationAngle;
        euler.order = "YZX";
        forwardDirection.setFromEuler(euler);
        impulse.applyQuaternion(forwardDirection);
        velocity.set(impulse.x, impulse.y, impulse.z);
        // const dampingFactor = 0.1;
        // zPosLook *= dampingFactor;
        // horizontalAngle = xPosLook * Math.PI

        verticalAngle = (zPosLook * Math.PI) / 3;

        verticalAngleMin = -1.7;
        verticalAngleMax = -0.24;
        verticalAngle = Math.max(
          verticalAngleMin,
          Math.min(verticalAngleMax, verticalAngle)
        );

      
        // Distance from target (character)

        x = distanceToTarget * Math.sin(horizontalAngle) * 7;
        y = distanceToTarget * Math.cos(verticalAngle);
        z = distanceToTarget * Math.sin(verticalAngle);

        // Calculate the camera offset vector based on angles and radius
        cameraOffset.set(x, y, z);

        rigidBodyQuat.copy(playerBodyRef.current.rotation());
        // const rigidBodyQuat = quat(playerBodyRef.current.rotation());
        rigidBodyVec.copy(playerBodyRef.current.translation());
        // Apply the character's rotation to the camera offset

        rotatedCameraOffset = cameraOffset
          .clone()
          .applyQuaternion(rigidBodyQuat);

        // Calculate the new camera position as the sum of character position and rotated offset
        newCameraPosition.copy(rigidBodyVec.clone().add(rotatedCameraOffset));
        currentPosition.lerp(newCameraPosition, 0.1);
        currentLookat.lerp(cameraSavedTarget, 0.1);

        camera.position.copy(currentPosition);
        camera.lookAt(currentLookat);
        setCameraStill(newCameraPosition);
      } else if (
        !snapTouchCircleLook.OrbitOn &&
        lookJoyOn
      ) {
        // currentPosition.copy(camera.position)
        camera.position.copy(cameraStill);
        camera.lookAt(currentLookat);
        currentPosition.copy(cameraStill);
      }
      if (!lookJoyOn && !snapTouchCircleLook.OrbitOn ) {
        // In follow mode, update the camera position and look-at as before
        const idealOffset = CalculateIdealOffset();
        const idealLookat = CalculateIdealLookat();
  
        currentPosition.lerp(idealOffset, t);
        currentLookat.lerp(idealLookat, t);
        camera.position.copy(currentPosition);
        camera.lookAt(currentLookat);
      }
      
    }

    // const rigidBodyPlayerVec = vec3(playerBodyRef.current.translation());
if(playerBodyRef.current){
    rigidBodyPlayerVec.copy(playerBodyRef.current.translation());

  }
    // statePlayerPositionS.playerCameraTargetPosition.copy(currentLookat);
    // playerposition.copy(rigidBodyPlayerVec);
    // statePlayerPositionS.playerPositionS.copy(rigidBodyPlayerVec);
    // const isRunning = speed >= 0.1;

  
  });
  const [playervisible, setplayervisible] = useState()

  useEffect(()=>{
    if(snapPlayerPosition.fpPlayer && snapIframeWindow.renderCanvas )
    {
      setplayervisible(false)
    }else if (!snapPlayerPosition.fpPlayer && snapIframeWindow.renderCanvas)
    {
      setplayervisible(true)
    }

    if(!snapIframeWindow.renderCanvas)
    {
      setplayervisible(false)
    }else if(snapIframeWindow.renderCanvas && !snapPlayerPosition.fpPlayer)
    {
      setplayervisible(true)
    }
    

    if(snapOrbitLocation.orbitClicked)
    {
      setplayervisible(false)
    }else if(!snapPlayerPosition.fpPlayer && !snapOrbitLocation.orbitClicked)
    {
      setplayervisible(true)
    }


  },[snapPlayerPosition.fpPlayer,snapIframeWindow.renderCanvas,snapOrbitLocation.orbitClicked])



  return (
    <RigidBody
      lockRotations={true}
      ref={playerBodyRef}
      colliders={false}
      position={currentRigidBodyPosition}
      // rotation={[0,-1.5,0]}
      restitution={0}
      friction={0} // was one
      gravityScale={true}
      // type={"dynamic"}
      type={statePlayerPositionS.homeclicked ? "fixed" : "dynamic"}
    >

      <primitive

        ref={playerRef}
        object={model}
        scale={12}
        visible={playervisible}
        dispose={null}
      >

      </primitive>

      <CapsuleCollider args={[7, 3]} position={[0, 10, 0]} />
      <Shadow position={[0, 0.2, 0]} scale={12} opacity={0.2} dispose={null} />
    </RigidBody>
  );
}





// // Global registry to store loaded textures and their names
// const loadedTextures = new Map();

// // Function to register a texture when it's loaded
// function registerTexture(texture, name) {
//     loadedTextures.set(texture.uuid, name);
// }

// // Function to unregister a texture when it's disposed
// function unregisterTexture(texture) {
//     loadedTextures.delete(texture.uuid);
// }

// // Function to get names of all textures currently in memory
// function getTextureNamesInMemory() {
//     const textureNames = [];
//     loadedTextures.forEach((name, textureUuid) => {
//         textureNames.push(name);
//     });
//     return textureNames;
// }

// // Function to log texture names in memory
// function logTextureNamesInMemory() {
//     const textureNames = getTextureNamesInMemory();
//     console.log("Textures currently in memory:", textureNames);
// }

// // Intercept TextureLoader.load to register loaded textures
// const originalLoad = THREE.TextureLoader.prototype.load;
// THREE.TextureLoader.prototype.load = function (url, onLoad, onProgress, onError) {
//     const texture = originalLoad.call(this, url, onLoad, onProgress, onError);
//     registerTexture(texture, url); // Pass the texture name here
//     return texture;
// };

// // Intercept Texture.dispose to unregister disposed textures
// const originalDispose = THREE.Texture.prototype.dispose;
// THREE.Texture.prototype.dispose = function () {
//     unregisterTexture(this);
//     originalDispose.call(this);
// };

// // Call the function to log texture names in memory periodically
// setInterval(logTextureNamesInMemory, 1000); // Log texture names every second