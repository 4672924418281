import { lazy, useRef} from "react";
import * as THREE from "three";
import GlbLoader from "../Utils/GlbLoader";
import { stateWallPush } from "../store";
import { useState } from "react";
import { useSnapshot } from "valtio";
import CheckInAreaEntryRoom from "../Utils/CheckInAreaEntryRoom";
import { stateInAreaRooms , stateMemory} from "../store";
import { useGLTF } from "@react-three/drei";
import { useTexture } from "@react-three/drei";
import { useEffect } from "react";
import { disposeGLTF } from "../Utils/disposeGLTF";
import { assignMaterialToObjects } from "../Utils/AssignMaterialToObject";

const Handelswege = lazy(()=> import ("../Themes/Handelswege/Handelswege"))
const Holzarbeit = lazy(()=> import ("../Themes/Holzarbeit/Holzarbeit"))
const Klima = lazy(()=> import ("../Themes/Klima/Klima"))
const Menschen = lazy(()=> import ("../Themes/Menschen/Menschen"))
const MuseenDerRegion = lazy(()=> import ("../Themes/MuseenDerRegion/MuseenDerRegion"))
const Salzabbau = lazy(()=> import ("../Themes/Salzabbau/Salzabbau"))



export default function EntryRoom() {



  const themeComponents = [
      MuseenDerRegion,
      Holzarbeit,
      Klima,
      Salzabbau,
      Handelswege,
      Menschen,
    ]
   

  // const themeComponents = useMemo(
  //   () => [
  //     MuseenDerRegion,
  //     Holzarbeit,
  //     Klima,
  //     Salzabbau,
  //     Handelswege,
  //     Menschen,
  //   ],
  //   []
  // );

  const snapInAreaRooms = useSnapshot(stateInAreaRooms);
  const snapMuseumPoints = useSnapshot(stateWallPush);
  const entryRoomRef = useRef();

  const { entryRoomModel} = GlbLoaderEntryRoom();





  return (
    <>

       <CheckInAreaEntryRoom
       squareA={170}
      squareB={170}
      position={[0, 0, 0]}
      ></CheckInAreaEntryRoom>


{snapInAreaRooms.inAreaRoomEntryRoom ?<primitive
        ref={entryRoomRef}
        object={entryRoomModel.scene}
        scale={1}
        
      /> : null }




      {snapMuseumPoints.activeThemeIndex != -1 && (
        <>
          {themeComponents.map(
            (Component, index) =>
              index === snapMuseumPoints.activeThemeIndex && (
                <Component key={index} />
              )
          )}
        </>
      )}
    </>
  );
}





const GlbLoaderEntryRoom = () => {

  const [modelLoadCount, setModelLoadCount] = useState(0);

 const snapStateMemory = useSnapshot(stateMemory)
 const snapInAreaRooms = useSnapshot(stateInAreaRooms)
const {imperfectionRoughMap , waterColorMap} = GlbLoader()

//  const landscape = useLoader(GLTFLoader,"../models/Museum01/MDR/Landscape.glb");
  // const themeMonitor = useLoader(GLTFLoader,
  //   "../models/EntryRoom/ThemeWalls/ThemeMonitor.glb",incrementLoadCount
  // );


  const whiteTransMaterial = new THREE.MeshBasicMaterial({
   
    color: "white",
    transparent: true,
    opacity: 0.35,
  
   
  });
  




  const [
   

    entryRoomFloorAOMap,
  

  
  
  ] = [
   
    "entryRoomFloorAO.jpg",

   

  
  
  
  
  ].map((filename) => !snapStateMemory.memoryLowDevice ? useTexture(`../overallTextures/EntryRoom/${filename}`) : useTexture(`../overallTextures/EntryRoom/LOW/${filename}`));








  // Now you can use memoizedArray in your component


  useEffect(() => {

    return () => {

      [

        entryRoomFloorAOMap,
  
       
      ].forEach(texture => {
    
        if (texture && texture.dispose) {
          texture.dispose();
        }
      });
    };
  }, [snapInAreaRooms.inAreaRoomEntryRoom]);

  

const [


  entryRoomModel,

  
] = useGLTF([

 
  "../models/EntryRoom/EntryRoom.glb",

 
])



    


// Usage in useEffect
useEffect(() => {

 if(!snapInAreaRooms.inAreaRoomEntryRoom){
  disposeGLTF([

    entryRoomModel.scene,

  ]);
}
  // Cleanup function to dispose of GLTF scenes
  return () => {
  
    disposeGLTF([

      entryRoomModel.scene,

 
    ]);
  };
}, [snapInAreaRooms.inAreaRoomEntryRoom]);
 
 

 
  // InfoTextMap.flipY = false;
  //TEXTURE LOADING

  //AO-ma
  entryRoomFloorAOMap.channel = 1;

  entryRoomFloorAOMap.flipY = false;
  entryRoomFloorAOMap.colorSpace = THREE.SRGBColorSpace;



  //Textures Overall





  const blackMattFloorMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.8,
    color: "#181818",
    metalness: 0,
    // map:   entryRoomFloorAOMap,
    roughnessMap: imperfectionRoughMap,
    aoMap: entryRoomFloorAOMap,

  });






  const blueGlasClear = new THREE.MeshStandardMaterial({
    // map: waterColorMap,
    map: waterColorMap,
    // normalMap: waterTileNormalMap,
    // // metalness: 1,
    // roughness: 0.01,
    transparent: true,
    opacity: 0.6,
  });


  const blackMattFloorMaterialGlossy = new THREE.MeshStandardMaterial({
    roughness: 0.05,
    color: "#181818",
    metalness: 0,
    // map:   entryRoomFloorAOMap,
    roughnessMap: imperfectionRoughMap,
    aoMap: entryRoomFloorAOMap,
    // aoMapIntensity: 1,
  });


  //ASSIGNMENT

  useEffect(() => {

    assignMaterialToObjects(entryRoomModel.scene,["Object2963"],blackMattFloorMaterialGlossy);
    assignMaterialToObjects(entryRoomModel.scene,[ "dachGlas"],blueGlasClear);
    assignMaterialToObjects(entryRoomModel.scene,["Floor03", "Floor02", "Floor01"],blackMattFloorMaterial);
    // assignMaterialToObjects(entryRoomModel.scene, ["Rahmen"], rahmenMaterial);

    assignMaterialToObjects(entryRoomModel.scene, ["iAreaLines"], whiteTransMaterial);
   
    
  }, []);


  return {
    entryRoomModel,

   

  
  };
};




