import { useEffect, useState } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { stateInAreaRooms } from "../store";
import { usePlayerPosition } from "./PlayerPositionContext";

import { stateWallPush } from "../store";

const modelPosition = new THREE.Vector3()


let newPlayerInsideArea
let horizontalDistanceSquared
let verticalDistanceSquared
function CheckInAreaEntryRoom(props) {
  const { squareA, squareB, position, } = props;
  const thresholdSquared = squareA * squareB;

  const [playerInsideArea, setPlayerInsideArea] = useState();
  const { playerPosition } = usePlayerPosition();


  
  useEffect(() => {
  
   
 if(playerInsideArea && stateWallPush.activeThemeIndex != -1 )
    // setAreaActive(playerInsideArea);
   {


    stateInAreaRooms.inAreaRoomEntryRoom = true;
    stateInAreaRooms.inAreaRoom = playerInsideArea;
    stateWallPush.activeThemeIndex = -1


  
   }
   else if (!playerInsideArea){


   }

  }, [playerInsideArea]);

  const verticalThresholdSquared =105

  useFrame(() => {
    if (playerPosition instanceof THREE.Vector3) {
      modelPosition.set(...position);
  
     horizontalDistanceSquared = playerPosition.distanceToSquared(
        new THREE.Vector3(modelPosition.x, playerPosition.y, modelPosition.z)
      );
  
     verticalDistanceSquared = Math.pow(playerPosition.y - modelPosition.y, 2);
  
      newPlayerInsideArea =
        horizontalDistanceSquared < thresholdSquared && verticalDistanceSquared < verticalThresholdSquared;
  
      if (newPlayerInsideArea !== playerInsideArea) {
        setPlayerInsideArea(newPlayerInsideArea);
        
      }
    }
  });

  return
  // (
  //   <mesh position={position} scale={[verticalThresholdSquared, verticalThresholdSquared, verticalThresholdSquared]} >
  //   {/* <cylinderGeometry attach="geometry" args={[2,2,2] } />
  //   <meshStandardMaterial attach="material" color="red" wireframe={true}/> */}
  // </mesh>
  // )
}

export default CheckInAreaEntryRoom;
